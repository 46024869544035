import { PaymentVoucherSpecification, RequestPaymentVoucher, SendPaymentVoucher } from '@/types';
import * as yup from 'yup';

const REQUIRED_ERROR_MESSAGE = 'Esse campo é obrigatório.';

const shouldApplySendToSchema = (value: SendPaymentVoucher[] | undefined, sendTo: SendPaymentVoucher) =>
  (value ?? []).includes(sendTo);

export const requestPaymentVoucherFormDataSchema: yup.ObjectSchema<RequestPaymentVoucher> = yup.object({
  requestsPaymentVoucher: yup.boolean().required(REQUIRED_ERROR_MESSAGE),
  sendTo: yup.array().when(['requestsPaymentVoucher', 'isEnabled'], {
    is: (requestsPaymentVoucher: boolean, isEnabled: boolean) => requestsPaymentVoucher && isEnabled,
    then: schema =>
      schema
        .of(yup.mixed<SendPaymentVoucher>().oneOf(Object.values(SendPaymentVoucher)).required(REQUIRED_ERROR_MESSAGE))
        .nullable(),
    otherwise: schema => schema.optional().nullable(),
  }),
  specifications: yup.array().when(['requestsPaymentVoucher', 'isEnabled'], {
    is: (requestsPaymentVoucher: boolean, isEnabled: boolean) => requestsPaymentVoucher && isEnabled,
    then: schema =>
      schema
        .of(
          yup
            .mixed<PaymentVoucherSpecification>()
            .oneOf(Object.values(PaymentVoucherSpecification))
            .required(REQUIRED_ERROR_MESSAGE),
        )
        .nullable(),
    otherwise: schema => schema.optional().nullable(),
  }),
  paymentVoucherEmail: yup.string().when('sendTo', {
    is: (value: SendPaymentVoucher[] | undefined) => shouldApplySendToSchema(value, SendPaymentVoucher.EMAIL),
    then: schema => schema.required(REQUIRED_ERROR_MESSAGE).email(),
    otherwise: schema => schema.optional(),
  }),
  paymentVoucherWhatsapp: yup.string().when('sendTo', {
    is: (value: SendPaymentVoucher[] | undefined) => shouldApplySendToSchema(value, SendPaymentVoucher.WHATSAPP),
    then: schema => schema.required(REQUIRED_ERROR_MESSAGE),
    otherwise: schema => schema.optional(),
  }),
});
