import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Flex, Grid, Toaster } from '@grupoboticario/flora-react';
import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { saveServiceCenter, events } from '@/api';
import { useServiceCenterForm } from '@/context';
import { PickUpStoreFormData } from '@/types';
import {
  FormWrapper,
  FormFieldset,
  InputSwitch,
  FormCheckbox,
  TogglableContent,
  FormInput,
  ControlledSelect,
  InputNote,
  FormFooter,
  GroupCheckbox,
} from '@/components';
import {
  checkboxPickUpStorePeriodValues,
  formatPickUpStoreFormData,
  normalizePickUpStoreFormData,
} from '@/components/pick-up-store-form/utils';
import { pickUpStoreFormDataSchema } from '@/components/pick-up-store-form/validators';
import {
  allowPickUpStoreCheckboxes,
  identificationRequirementsCheckboxes,
  proofOfAddressCheckboxes,
  proofOfAddressSpecificationsCheckboxes,
  thirdPartySupportInfoCheckboxes,
} from './constants/checkboxValues';

const generateDefaultValues = (): PickUpStoreFormData => ({
  allowPickUpStore: false,
  hasIdentificationRequirements: false,
  hasProofOfAddress: false,
  hasScheduleForPickUp: false,
  hasThirdPartySupport: false,
  hasMinimumPickUpPeriod: false,
  hasMaximumPickUpPeriod: false,
});

const handleDataSubmit = async (data: PickUpStoreFormData, csId?: string) => {
  if (csId === undefined) {
    throw new Error('Service Center ID não pode ser nulo');
  }

  const formattedData = formatPickUpStoreFormData(data, csId);

  return saveServiceCenter(formattedData);
};

const PickUpStoreForm = (): JSX.Element => {
  const { goToNextStep, state, setPickUpStore, isEditMode } = useServiceCenterForm();

  const defaultValues = useMemo(
    () => state.formData.pickUpInStore ?? generateDefaultValues(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const methods = useForm<PickUpStoreFormData>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(pickUpStoreFormDataSchema),
    mode: 'onBlur',
    defaultValues,
  });

  const {
    trigger,
    formState: { errors, dirtyFields, isDirty, isSubmitting },
  } = methods;

  useEffect(() => {
    events.emit('UPDATE_CS_DATA', {
      isSubmitting,
    });
  }, [isSubmitting]);

  const allowPickUpStoreValue = methods.watch('allowPickUpStore');

  const shouldSkipRequestOnSubmit = !isDirty && isEditMode && !!state.formData.pickUpInStore;

  const onSubmitPickUpStoreFormData = useCallback(
    async (data: PickUpStoreFormData) => {
      try {
        if (shouldSkipRequestOnSubmit) {
          goToNextStep();
          return;
        }
        const response = await handleDataSubmit(data, state.formData.id);

        if (!response.pickUpStore) {
          throw new Error('Formato de objeto não suportado, esperado PickUpStore');
        }

        setPickUpStore(normalizePickUpStoreFormData(response.pickUpStore));

        goToNextStep();
      } catch (e) {
        Toaster.notify({
          kind: 'error',
          description: 'Um erro ocorreu ao salvar esta etapa. Por favor, tente novamente mais tarde.',
          origin: 'right-top',
          duration: 60000,
          button: 'Fechar',
          closeWhenClick: true,
        });
      }
    },
    [goToNextStep, shouldSkipRequestOnSubmit, setPickUpStore, state.formData.id],
  );

  return (
    <FormProvider {...methods}>
      <Box as="form" data-testid="pick-up-store-form" onSubmit={methods.handleSubmit(onSubmitPickUpStoreFormData)}>
        <FormWrapper gap="$2" css={{ padding: '$4 $8' }}>
          <FormFieldset hasDivider={false}>
            <InputSwitch label="Autoriza retirada?" registerName="allowPickUpStore" onText="Sim" offText="Não" />
            <Flex direction="column" gap="$4" css={{ paddingTop: '$4' }}>
              {allowPickUpStoreCheckboxes.map(checkboxData => (
                <FormCheckbox
                  key={`allowPickUpStore__${checkboxData.id}`}
                  {...checkboxData}
                  registerName="pickUpStoreInfo"
                  disabled={!allowPickUpStoreValue}
                />
              ))}
            </Flex>
          </FormFieldset>
        </FormWrapper>
        <FormWrapper title="Condições gerais" gap="$2" css={{ padding: '$4 $8' }}>
          <FormFieldset hasDivider={false}>
            <TogglableContent
              title="Exige documento para retirada?"
              switchRegisterName="hasIdentificationRequirements"
              disabled={!allowPickUpStoreValue}
            >
              <GroupCheckbox
                registerName="identificationRequirements"
                checkboxes={identificationRequirementsCheckboxes}
              />
            </TogglableContent>
          </FormFieldset>
          <FormFieldset>
            <TogglableContent
              title="Há especificações sobre os comprovantes de endereço aceitos?"
              switchRegisterName="hasProofOfAddress"
              disabled={!allowPickUpStoreValue}
            >
              <GroupCheckbox
                registerName="proofOfAddress"
                title="Tipos de comprovantes"
                checkboxes={proofOfAddressCheckboxes}
              />

              <GroupCheckbox
                registerName="proofOfAddressSpecifications"
                title="Especificações"
                checkboxes={proofOfAddressSpecificationsCheckboxes}
              />
            </TogglableContent>
          </FormFieldset>
          <FormFieldset>
            <TogglableContent
              title="Exige agendamento para retirada?"
              switchRegisterName="hasScheduleForPickUp"
              disabled={!allowPickUpStoreValue}
            >
              <FormInput
                id="orientações"
                labelText="Orientações:"
                registerName="scheduleForPickUpNote"
                data-testid="schedule-pick-up-note"
                placeholder="Digite aqui"
                disabled={!allowPickUpStoreValue}
              />
            </TogglableContent>
          </FormFieldset>
          <FormFieldset>
            <TogglableContent
              title="Autoriza a retirada por terceiros?"
              switchRegisterName="hasThirdPartySupport"
              disabled={!allowPickUpStoreValue}
            >
              <GroupCheckbox registerName="thirdPartySupportInfo" checkboxes={thirdPartySupportInfoCheckboxes} />
            </TogglableContent>
          </FormFieldset>
          <FormFieldset>
            <TogglableContent
              title="Prazo mínimo para retirada?"
              switchRegisterName="hasMinimumPickUpPeriod"
              disabled={!allowPickUpStoreValue}
            >
              <Grid templateColumns="repeat(2, 6fr)" gap="$4">
                <FormInput
                  id="quantidade"
                  labelText="Quantidade:"
                  registerName="minimumPickUpPeriodQty"
                  data-testid="minimum-pick-up-period-qty"
                  placeholder="Digite aqui"
                  disabled={!allowPickUpStoreValue}
                  onChange={() => trigger('maximumPickUpPeriodQty')}
                />

                <ControlledSelect
                  registerName="minimumPickUpPeriodInfo"
                  dropdownItems={checkboxPickUpStorePeriodValues}
                  data-testid="minimum-pick-up-period-info"
                  id="periodo-minimo"
                  labelText="Período:"
                  optionalText="Selecione"
                  invalidText={errors?.minimumPickUpPeriodInfo?.message}
                  invalid={!!errors?.minimumPickUpPeriodInfo}
                  data-valid={dirtyFields.minimumPickUpPeriodInfo && !errors.minimumPickUpPeriodInfo}
                  onBlur={() => trigger('maximumPickUpPeriodInfo')}
                  disabled={!allowPickUpStoreValue}
                />
              </Grid>
            </TogglableContent>
          </FormFieldset>
          <FormFieldset>
            <TogglableContent
              title="Prazo máximo para retirada?"
              switchRegisterName="hasMaximumPickUpPeriod"
              disabled={!allowPickUpStoreValue}
            >
              <Grid templateColumns="repeat(2, 6fr)" gap="$4">
                <FormInput
                  id="quantidade"
                  labelText="Quantidade:"
                  registerName="maximumPickUpPeriodQty"
                  data-testid="maximum-pick-up-period-qty"
                  placeholder="Digite aqui"
                  disabled={!allowPickUpStoreValue}
                  onChange={() => trigger('minimumPickUpPeriodQty')}
                />

                <ControlledSelect
                  registerName="maximumPickUpPeriodInfo"
                  data-testid="maximum-pick-up-period-info"
                  dropdownItems={checkboxPickUpStorePeriodValues}
                  id="periodo-maximo"
                  labelText="Período:"
                  optionalText="Selecione"
                  onBlur={() => trigger('minimumPickUpPeriodInfo')}
                  invalidText={errors?.maximumPickUpPeriodInfo?.message}
                  invalid={!!errors?.maximumPickUpPeriodInfo}
                  data-valid={dirtyFields.maximumPickUpPeriodInfo && !errors.maximumPickUpPeriodInfo}
                  disabled={!allowPickUpStoreValue}
                />
              </Grid>
            </TogglableContent>
          </FormFieldset>
          <FormFieldset>
            <InputNote registerName="note" />
          </FormFieldset>
        </FormWrapper>

        <FormFooter />
      </Box>
    </FormProvider>
  );
};

export { PickUpStoreForm };
