import { useServiceCenterForm } from '@/context';
import { BusinessHoursFormData, DayFormData } from '@/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import {
  BusinessScheduleCalendar,
  OpenHoursList,
  businessHoursFormDataSchema,
  formatBusinessHoursFormData,
  normalizeBusinessHoursFormData,
} from '@/components/business-hours-form/';
import { events, saveServiceCenter } from '@/api';
import { Box, FloraButton, Toaster } from '@grupoboticario/flora-react';
import { CalendarIcon } from '@grupoboticario/flora-react-icons';
import { FormWrapper, FormFieldset, TogglableContent, InputNote, FormFooter } from '@/components/shared';

const generateDefaultValues = (): BusinessHoursFormData => ({
  holidays: { openOnHolidays: false, hours: [{ opensAt: '', closesAt: '' }] },
  days: [{ closed: false, day: [], hours: [{ opensAt: '', closesAt: '' }] }],
});

const maximumSelectedDays = 7;
const defaultHoursValues = {
  opensAt: '',
  closesAt: '',
};

const defaultBusinessScheduleCalendarValues: DayFormData = {
  day: [],
  hours: [defaultHoursValues],
  closed: false,
};

const handleBusinessHoursFormDataSubmit = async (data: BusinessHoursFormData, csId?: string) => {
  if (csId === undefined) {
    throw new Error('Service Center ID não pode ser nulo');
  }

  const formattedData = formatBusinessHoursFormData(data, csId);
  return saveServiceCenter(formattedData);
};

const BusinessHoursForm = (): JSX.Element => {
  const { goToNextStep, setBusinessHours, state, isEditMode } = useServiceCenterForm();

  const defaultValues = useMemo(
    () => state.formData.businessHours ?? generateDefaultValues(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const methods = useForm<BusinessHoursFormData>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(businessHoursFormDataSchema),
    mode: 'onBlur',
    defaultValues,
  });
  const {
    handleSubmit,
    control,
    formState: { isDirty, isSubmitting },
    watch,
  } = methods;

  useEffect(() => {
    events.emit('UPDATE_CS_DATA', {
      isSubmitting,
    });
  }, [isSubmitting]);

  const onSubmitBusinessHoursData = useCallback(
    async (data: BusinessHoursFormData) => {
      try {
        if (!isDirty && isEditMode) {
          goToNextStep();
          return;
        }
        const response = await handleBusinessHoursFormDataSubmit(data, state.formData.id);

        setBusinessHours(normalizeBusinessHoursFormData(response.businessHours));
        goToNextStep();
      } catch {
        Toaster.notify({
          kind: 'error',
          description: 'Um erro ocorreu ao salvar esta etapa. Por favor, tente novamente mais tarde.',
          origin: 'right-top',
          duration: 60000,
          button: 'Fechar',
          closeWhenClick: true,
        });
      }
    },
    [goToNextStep, isDirty, isEditMode, setBusinessHours, state.formData.id],
  );

  const { fields, append } = useFieldArray({
    name: 'days',
    control,
  });

  const businessScheduleCalendarData = watch('days');
  const selectedDaysQuantity = (businessScheduleCalendarData ?? []).reduce(
    (prevValue, currValue) => prevValue + (currValue.day ?? []).length,
    0,
  );
  return (
    <FormProvider {...methods}>
      <Box as="form" onSubmit={handleSubmit(onSubmitBusinessHoursData)} data-testid="business-hours-form">
        <FormWrapper css={{ padding: '$6 $23 $14 $23 ' }} gap="$2">
          <FormFieldset hasDivider={false}>
            {fields.map((field, index) => {
              return <BusinessScheduleCalendar index={index} key={field.id} />;
            })}
            <FloraButton
              type="button"
              size="small"
              hierarchy="tertiary"
              css={{ marginTop: '$1' }}
              onClick={() => {
                append(defaultBusinessScheduleCalendarValues);
              }}
              icon={<CalendarIcon size="16" />}
              has="iconLeft"
              isFull={false}
              disabled={selectedDaysQuantity === maximumSelectedDays}
              data-testid="add-week-button"
            >
              Adicionar dias da semana
            </FloraButton>
          </FormFieldset>

          <FormFieldset>
            <TogglableContent
              title="Abre aos feriados?"
              switchRegisterName="holidays.openOnHolidays"
              css={{ paddingTop: '$6' }}
            >
              <OpenHoursList parentRegisterName="holidays" />
            </TogglableContent>
          </FormFieldset>

          <FormFieldset>
            <InputNote registerName="note" />
          </FormFieldset>
        </FormWrapper>
        <FormFooter />
      </Box>
    </FormProvider>
  );
};

export { BusinessHoursForm };
