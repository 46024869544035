import { useEffect, useState } from 'react';
import { FloraButton, Flex } from '@grupoboticario/flora-react';
import { ArrowDownIcon, ArrowUpIcon, CheckSingleIcon } from '@grupoboticario/flora-react-icons';
import { events } from '@/api';
import { useServiceCenterForm } from '@/context';
import { SaveFormData } from '@/types';

interface FormFooterProps {
  hasBackButton?: boolean;
  isLastStep?: boolean;
  isNextButtonDisabled?: boolean;
}
const FormFooter = ({
  hasBackButton = true,
  isLastStep = false,
  isNextButtonDisabled = false,
}: FormFooterProps): JSX.Element => {
  const { goToPreviousStep } = useServiceCenterForm();
  const [formState, setFormState] = useState<SaveFormData>({
    isSubmitting: false,
  });

  useEffect(() => {
    events.on('UPDATE_CS_DATA', load => setFormState(load));
    return () => {
      events.off('UPDATE_CS_DATA');
    };
  }, []);

  return (
    <Flex justify={hasBackButton ? 'space-between' : 'end'}>
      {hasBackButton && (
        <FloraButton
          hierarchy="secondary"
          onClick={goToPreviousStep}
          icon={<ArrowUpIcon size="16" />}
          has="iconRight"
          data-testid="form-footer-previous-btn"
        >
          Voltar
        </FloraButton>
      )}
      <FloraButton
        type="submit"
        disabled={isNextButtonDisabled || formState.isSubmitting}
        isLoading={formState.isSubmitting}
        has="iconRight"
        icon={isLastStep ? <CheckSingleIcon size="16" /> : <ArrowDownIcon size="16" />}
      >
        {isLastStep ? 'Concluir cadastro' : 'Salvar e continuar'}
      </FloraButton>
    </Flex>
  );
};

export { FormFooter };
