import { DocumentationFormData } from '@/types';

import * as yup from 'yup';

const REQUIRED_ERROR_MESSAGE = 'Esse campo é obrigatório.';

export const documentationFormDataSchema: yup.ObjectSchema<DocumentationFormData> = yup.object({
  hasAdditionalDocumentation: yup.boolean(),
  documentationNote: yup.string().when(['hasAdditionalDocumentation'], {
    is: true,
    then: schema => schema.required(REQUIRED_ERROR_MESSAGE),
    otherwise: schema => schema.optional(),
  }),
});
