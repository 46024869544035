import * as yup from 'yup';
import { formatCurrencyStringToNumber } from '../formatCurrencyStringToNumber';

const MIN_VALUE_ERROR_MESSAGE = 'Insira um valor maior que R$0,00.';

export const minCurrencyValueTestFn = (value: string | undefined) => {
  const formattedValue = formatCurrencyStringToNumber(value);
  const parsedValue = Number.parseFloat(formattedValue ?? '0');

  return Number.isNaN(parsedValue) ? true : parsedValue > 0;
};

export const minCurrencyValueTest: yup.TestConfig<string | undefined> = {
  name: 'min-currency-value-test',
  message: MIN_VALUE_ERROR_MESSAGE,
  test: minCurrencyValueTestFn,
};
