import { AcceptsInstallmentPayments, InstallmentCondition, InstallmentConditionData } from '@/types';
import { minCurrencyValueTest } from '@/utils';
import * as yup from 'yup';

const REQUIRED_ERROR_MESSAGE = 'Esse campo é obrigatório.';
const MIN_INSTALLMENTS_ERROR_MESSAGE = 'O valor mínimo de parcelas é 2.';

const installmentConditionDataSchema: yup.ObjectSchema<InstallmentConditionData> = yup.object({
  condition: yup.mixed<InstallmentCondition>().oneOf(Object.values(InstallmentCondition)),
  value: yup.string().when('condition', {
    is: (value: InstallmentCondition) => value !== InstallmentCondition.INDEPENDENT,
    then: schema => schema.test(minCurrencyValueTest),
    otherwise: schema => schema.optional(),
  }),
  maximumInstallments: yup
    .number()
    .typeError(MIN_INSTALLMENTS_ERROR_MESSAGE)
    .optional()
    .min(2, MIN_INSTALLMENTS_ERROR_MESSAGE),
});

export const acceptsInstallmentsFormDataSchema: yup.ObjectSchema<AcceptsInstallmentPayments> = yup.object({
  hasInstallmentOptions: yup.boolean().required(),
  installmentOptions: yup.array().when('hasInstallmentOptions', {
    is: true,
    then: schema => schema.of(installmentConditionDataSchema),
    otherwise: schema => schema.optional(),
  }),
  hasMinimumInstallmentValue: yup.boolean().required(),
  minimumInstallmentValue: yup.string().when('hasMinimumInstallmentValue', {
    is: true,
    then: schema => schema.required(REQUIRED_ERROR_MESSAGE).test(minCurrencyValueTest),
    otherwise: schema => schema.optional(),
  }),
});
