import { FormCheckbox } from '@/components/shared';

export type CheckboxDataType = Pick<React.ComponentProps<typeof FormCheckbox>, 'id' | 'label' | 'value'>;

export const allowPickUpStoreCheckboxes: CheckboxDataType[] = [
  {
    id: 'obrigatorio',
    label: 'Apenas retirada, não realiza entregas',
    value: 'obrigatorio',
  },
  {
    id: 'somente-primeiro-pedido',
    label: 'Somente no primeiro pedido',
    value: 'somente-primeiro-pedido',
  },
  {
    id: 'a-partir-segundo-pedido',
    label: 'Somente a partir do segundo pedido',
    value: 'a-partir-segundo-pedido',
  },
];

export const identificationRequirementsCheckboxes: CheckboxDataType[] = [
  {
    id: 'documento-original-foto',
    label: 'Documento original com foto',
    value: 'documento-original-foto',
  },
  {
    id: 'copia-documento-foto',
    label: 'Cópia do documento com foto',
    value: 'copia-documento-foto',
  },
];

export const proofOfAddressCheckboxes: CheckboxDataType[] = [
  {
    id: 'contas-consumo',
    label: 'Contas de consumo (água, luz, telefone, gás, internet etc)',
    value: 'contas-consumo',
  },
  {
    id: 'fatura-cartao-credito',
    label: 'Fatura de cartão de crédito',
    value: 'fatura-cartao-credito',
  },
  {
    id: 'declaração-imposto-renda',
    label: 'Declaração de Imposto de Renda',
    value: 'declaração-imposto-renda',
  },
  {
    id: 'iptu-ou-ipva',
    label: 'IPTU e/ou IPVA',
    value: 'iptu-ou-ipva',
  },
  {
    id: 'contrato-locacao',
    label: 'Contrato de locação',
    value: 'contrato-locacao',
  },

  {
    id: 'declaracao-residencia',
    label: 'Declaração de residência',
    value: 'declaracao-residencia',
  },
];

export const proofOfAddressSpecificationsCheckboxes: CheckboxDataType[] = [
  {
    id: 'comprovante-titular-cadastr',
    label: 'Comprovante no nome do titular do cadastro',
    value: 'comprovante-titular-cadastro',
  },
  {
    id: 'comprovante-nome-parente-cadastro',
    label: 'Comprovante no nome de parente de primeiro grau (mãe, pai, filhos etc) do titular do cadastro',
    value: 'comprovante-nome-parente-cadastro',
  },
  {
    id: 'copia',
    label: 'Cópia',
    value: 'copia',
  },
  {
    id: 'original',
    label: 'Original',

    value: 'original',
  },
];

export const thirdPartySupportInfoCheckboxes: CheckboxDataType[] = [
  {
    id: 'apresentar-documento-original-titular',
    label: 'Apresentar documento original com foto do titular',
    value: 'apresentar-documento-original-titular',
  },
  {
    id: 'apresentar-copia-documento-titular',
    label: 'Apresentar cópia do documento com foto do titular',
    value: 'apresentar-copia-documento-titular',
  },
  {
    id: 'apresentar-termo-autorizacao-retirada',
    label: 'Apresentar termo de autorização de retirada',
    value: 'apresentar-termo-autorizacao-retirada',
  },
];
