export enum DayOfTheWeek {
  MONDAY = 'Mon',
  TUESDAY = 'Tue',
  WEDNESDAY = 'Wed',
  THURSDAY = 'Thu',
  FRIDAY = 'Fri',
  SATURDAY = 'Sat',
  SUNDAY = 'Sun',
}

export enum ProductCategory {
  PERFUMARIA = 'perfumaria',
  MAQUIAGEM = 'maquiagem',
  CORPO_E_BANHO = 'corpo-banho',
  CABELOS = 'cabelos',
  PELE = 'pele',
}

export enum InstallmentCondition {
  NONE = 'none',
  PURCHASES_UNTIL = 'purchases-until',
  PURCHASES_FROM = 'purchases-from',
  INDEPENDENT = 'independent',
}

export enum SendPaymentVoucher {
  OPERATIONAL_MANAGER = 'operational-manager',
  EMAIL = 'email',
  WHATSAPP = 'whatsapp',
}

export enum PaymentVoucherSpecification {
  INCLUDE_ORDER_INFO = 'include-order-info',
  INCLUDE_RESELLER_INFO = 'include-reseller-info',
}

export enum PaymentLocationInfo {
  IN_PERSON = 'in-person',
  ON_DELIVERY = 'on-delivery',
}

export enum PixKey {
  CPF_CNPJ = 'cpf-cnpj',
  PHONE_NUMBER = 'phone-number',
  EMAIL = 'email',
  RANDOM = 'random',
}

export enum AccountType {
  CURRENT_ACCOUNT = 'current-account',
  SAVINGS_ACCOUNT = 'savings-account',
}

export enum PickUpStoreInfoInstruction {
  OBRIGATORIO = 'obrigatorio',
  SOMENTE_PRIMEIRO_PEDIDO = 'somente-primeiro-pedido',
  A_PARTIR_SEGUNDO_PEDIDO = 'a-partir-segundo-pedido',
}

export enum IdentificationRequirement {
  DOCUMENTO_ORIGINAL_FOTO = 'documento-original-foto',
  COPIA_DOCUMENTO_FOTO = 'copia-documento-foto',
}

export enum ProofOfAddressRequirement {
  CONTAS_CONSUMO = 'contas-consumo',
  FATURA_CARTAO_CREDITO = 'fatura-cartao-credito',
  DECLARACAO_IMPOSTO_RENDA = 'declaração-imposto-renda',
  IPTU_OU_IPVA = 'iptu-ou-ipva',
  CONTRATO_LOCACAO = 'contrato-locacao',
  DECLARACAO_RESIDENCIA = 'declaracao-residencia',
}

export enum ProofOfAddressSpecificationsRequirement {
  COMPROVANTE_TITULAR_CADASTRO = 'comprovante-titular-cadastro',
  COMPROVANTE_NOME_PARENTE_CADASTRO = 'comprovante-nome-parente-cadastro',
  COPIA = 'copia',
  ORIGINAL = 'original',
}

export enum ThirdPartySupportRequirement {
  APRESENTAR_DOCUMENTO_ORIGINAL_TITULAR = 'apresentar-documento-original-titular',
  APRESENTAR_COPIA_DOCUMENTO_TITULAR = 'apresentar-copia-documento-titular',
  APRESENTAR_TERMO_AUTORIZACAO_RETIRADA = 'apresentar-termo-autorizacao-retirada',
}

export enum PickUpLimitInfo {
  NONE = 'NONE',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  DAYS = 'DAYS',
}

export enum StarterKitInstruction {
  ADQUIRIDO_POSTERIORMENTE = 'adquirido-posteriormente',
  INFORMAR_INDISPONIBILIDADE = 'informar-indisponibilidade',
}

export enum DeliveryThirdPartyObservation {
  CONTRATO_ASSINADO = 'contrato-assinado',
  APOS_SEGUNDA_COMPRA = 'apos-segunda-compra',
  DOCUMENTO_TITULAR = 'doc-foto-titular',
  COPIA_DOCUMENTO_TITULAR = 'copia-documento-titular',
  TERMO_AUTORIZACAO_RETIRADA = 'termo-autorizacao-retirada',
}

export enum PickUpStorePeriod {
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
}
