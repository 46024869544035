import { DocumentationFormData, SaveDocumentationRequestType } from '@/types';

export const formatDocumentationFormData = (
  formData: DocumentationFormData,
  id: string,
): SaveDocumentationRequestType => ({
  _id: id,
  step: '9',
  documentation: {
    documentationNote: formData.documentationNote,
    hasAdditionalDocumentation: formData.hasAdditionalDocumentation,
  },
});
