import { useCallback, useEffect, useMemo } from 'react';
import { Box, Flex, FloraTypography, Toaster } from '@grupoboticario/flora-react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { events, saveServiceCenter } from '@/api';
import { useServiceCenterForm } from '@/context';
import { DeliveryFormData, deliveryThirdPartiesCheckboxValues } from '@/types';
import {
  FormWrapper,
  FormFieldset,
  InputSwitch,
  FormCheckbox,
  TogglableContent,
  ChipInput,
  InputNote,
  FormFooter,
  FormInputCurrency,
} from '@/components/shared';
import { SpecialConditions } from '@/components/delivery-form';
import { formatDeliveryFormData, normalizeDeliveryFormData } from '@/components/delivery-form/utils';
import { deliveryFormDataSchema } from '@/components/delivery-form/validators';

const generateDefaultValues = (): DeliveryFormData => ({
  allowDeliveryToThirdParties: false,
  hasDelivery: false,
  hasFreeDeliveryDuringPandemic: false,
  hasNotAllowedDeliveryRegions: false,
  hasSpecialConditions: false,
});

const handleDeliveryFormSubmit = async (data: DeliveryFormData, csId?: string) => {
  if (csId === undefined) {
    throw new Error('Service Center ID não pode ser nulo');
  }
  const formattedData = formatDeliveryFormData(data, csId);
  return saveServiceCenter(formattedData);
};

const DeliveryForm = (): JSX.Element => {
  const { goToNextStep, state, setDelivery, isEditMode } = useServiceCenterForm();

  const defaultValues = useMemo(
    () => state.formData.delivery ?? generateDefaultValues(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const methods = useForm<DeliveryFormData>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(deliveryFormDataSchema),
    mode: 'onBlur',
    defaultValues,
  });
  const {
    formState: { isDirty, isSubmitting },
  } = methods;

  const shouldSkipRequestOnSubmit = useMemo(
    () => !isDirty && isEditMode && !!state.formData.delivery,
    [isDirty, isEditMode, state.formData.delivery],
  );

  useEffect(() => {
    events.emit('UPDATE_CS_DATA', {
      isSubmitting,
    });
  }, [isSubmitting]);

  const onSubmitDeliveryData = useCallback(
    async (data: DeliveryFormData) => {
      try {
        if (shouldSkipRequestOnSubmit) {
          goToNextStep();
          return;
        }

        const response = await handleDeliveryFormSubmit(data, state.formData.id);
        const normalizedFormData = normalizeDeliveryFormData(response.delivery);
        setDelivery(normalizedFormData);
        goToNextStep();
      } catch (e) {
        Toaster.notify({
          kind: 'error',
          description: 'Um erro ocorreu ao salvar esta etapa. Por favor, tente novamente mais tarde.',
          origin: 'right-top',
          duration: 60000,
          button: 'Fechar',
          closeWhenClick: true,
        });
      }
    },
    [shouldSkipRequestOnSubmit, state.formData.id, setDelivery, goToNextStep],
  );

  const [hasDeliveryValue, hasFreeDeliveryValue, allowDeliveryToThirdPartiesValue] = methods.watch([
    'hasDelivery',
    'hasFreeDelivery',
    'allowDeliveryToThirdParties',
  ]);

  return (
    <FormProvider {...methods}>
      <Box as="form" onSubmit={methods.handleSubmit(onSubmitDeliveryData)}>
        <FormWrapper gap="$2">
          <FormFieldset hasDivider={false}>
            <InputSwitch label="Realiza entrega?" registerName="hasDelivery" onText="Sim" offText="Não" />
            <Box
              css={{
                marginTop: '$4',
              }}
            >
              <FormInputCurrency
                registerName="deliveryCost"
                id="delivery-cost"
                labelText="Valor do frete:"
                data-testid="delivery-cost-testid"
                disabled={!hasDeliveryValue || hasFreeDeliveryValue}
                defaultValue={defaultValues.deliveryCost}
              />
            </Box>
            <Flex gap="$4" direction="column" css={{ marginTop: '$3' }}>
              <FormCheckbox
                label="Frete gratuito"
                registerName="hasFreeDelivery"
                id="has-free-delivery"
                disabled={!hasDeliveryValue}
              />
              <FormCheckbox
                label="Frete cortesia"
                registerName="hasFreeDeliveryOnFirstOrder"
                id="has-free-delivery-on-first-order"
                disabled={!hasDeliveryValue}
              />
            </Flex>
          </FormFieldset>
        </FormWrapper>

        <FormWrapper gap="$2" css={{ marginTop: '$6' }}>
          <FloraTypography
            fontSize="exceptionsRestricted"
            css={{ gridColumn: 'span 12', marginBottom: '$4', color: '$nonInteractiveAuxiliar' }}
          >
            Condições gerais
          </FloraTypography>

          <FormFieldset hasDivider={false}>
            <TogglableContent
              title="Condições especiais por valor da compra?"
              switchRegisterName="hasSpecialConditions"
              disabled={!hasDeliveryValue}
            >
              <SpecialConditions />
            </TogglableContent>
          </FormFieldset>

          <FormFieldset>
            <InputSwitch
              label="Frete gratuito durante a pandemia?"
              registerName="hasFreeDeliveryDuringPandemic"
              onText="Sim"
              offText="Não"
              disabled={!hasDeliveryValue}
            />
          </FormFieldset>

          <FormFieldset>
            <TogglableContent
              title="Entrega bloqueada para alguma região?"
              switchRegisterName="hasNotAllowedDeliveryRegions"
              disabled={!hasDeliveryValue}
            >
              <ChipInput
                id="notAllowedDeliveryRegions"
                labelText="Região:"
                optionalText="Separe os itens por ponto e vírgula e aperte enter"
                registerName="notAllowedDeliveryRegions"
                isTextInput
              />
            </TogglableContent>
          </FormFieldset>

          <FormFieldset>
            <InputSwitch
              label="Entrega para terceiros?"
              registerName="allowDeliveryToThirdParties"
              onText="Sim"
              offText="Não"
              disabled={!hasDeliveryValue}
              onCheckedChange={() => {
                methods.resetField('deliveryToThirdPartiesRules');
              }}
            />
            <Flex direction="column" gap="$4" css={{ marginTop: '$4' }}>
              {deliveryThirdPartiesCheckboxValues.map(checkboxData => (
                <FormCheckbox
                  key={checkboxData.id}
                  id={checkboxData.id}
                  label={checkboxData.label}
                  registerName="deliveryToThirdPartiesRules"
                  value={checkboxData.id}
                  disabled={!hasDeliveryValue || !allowDeliveryToThirdPartiesValue}
                />
              ))}
            </Flex>
          </FormFieldset>

          <FormFieldset>
            <InputNote registerName="note" disabled={!hasDeliveryValue} />
          </FormFieldset>
        </FormWrapper>
        <FormFooter />
      </Box>
    </FormProvider>
  );
};

export { DeliveryForm };
