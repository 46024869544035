import { OrderIncrement, OrderIncrementFormData, SaveOrderIncrementRequestType } from '@/types';
import { formatCurrencyStringToNumber, getValueByCondition } from '@/utils';

const generatAllowIncrementDisabledPayload = (): OrderIncrement => ({
  allowIncrement: false,
  hasRequiredQuantity: false,
  hasMaximumValue: false,
  hasMinimumValue: false,
  hasSpecificProducts: false,
  hasCategories: false,
});

const generatAllowIncrementEnabledPayload = (formData: OrderIncrementFormData): OrderIncrement => {
  const { hasCategories, hasMaximumValue, hasMinimumValue, hasRequiredQuantity, hasSpecificProducts } = formData;

  return {
    allowIncrement: true,
    hasRequiredQuantity,
    requiredQuantity: getValueByCondition(hasRequiredQuantity, `${formData.requiredQuantity}`),
    hasMaximumValue,
    maximumValue: getValueByCondition(hasMaximumValue, formatCurrencyStringToNumber(formData.maximumValue)),
    hasMinimumValue,
    minimumValue: getValueByCondition(hasMinimumValue, formatCurrencyStringToNumber(formData.minimumValue)),
    hasSpecificProducts,
    specificProducts: getValueByCondition(hasSpecificProducts, formData.specificProducts),
    hasCategories,
    categories: getValueByCondition(hasCategories, formData.categories),
    note: formData.note,
  };
};

export const formatOrderIncrementFormData = (
  formData: OrderIncrementFormData,
  id: string,
): SaveOrderIncrementRequestType => ({
  _id: id,
  step: '3',
  orderIncrement: formData.allowIncrement
    ? generatAllowIncrementEnabledPayload(formData)
    : generatAllowIncrementDisabledPayload(),
});
