import { FormFieldset, InputNote } from '@/components/';
import { Flex } from '@grupoboticario/flora-react';
import { PaymentHeader, PaymentLocations } from '@/components/payment-methods-form/';

const DebitCardPayment = () => (
  <Flex direction="column">
    <PaymentHeader />
    <PaymentLocations parentRegisterName="debitCardPayment" />
    <FormFieldset>
      <InputNote registerName="debitCardPayment.note" />
    </FormFieldset>
  </Flex>
);

export { DebitCardPayment };
