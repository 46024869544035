import { StarterPack, StarterPackFormData, SaveStarterPackRequestType } from '@/types';
import { getValueByCondition } from '@/utils';

const allowStarterKitDisabledPayload = (): StarterPack => ({
  allowStarterKit: false,
  onlyIfStarterKitIsUnavailable: false,
  hasStarterKitInstruction: false,
  hasSpecificProducts: false,
});

const allowStarterKitEnabledPayload = (formData: StarterPackFormData): StarterPack => {
  const { onlyIfStarterKitIsUnavailable, hasStarterKitInstruction, hasSpecificProducts } = formData;

  return {
    allowStarterKit: true,
    onlyIfStarterKitIsUnavailable: !!onlyIfStarterKitIsUnavailable,
    hasStarterKitInstruction,
    starterKitInstruction: getValueByCondition(hasStarterKitInstruction, formData.starterKitInstruction),
    hasSpecificProducts,
    specificProducts: getValueByCondition(hasSpecificProducts, formData.specificProducts),
    note: formData.note,
  };
};

export const formatStarterPackFormData = (formData: StarterPackFormData, id: string): SaveStarterPackRequestType => ({
  _id: id,
  step: '4',
  starterPack: formData.allowStarterKit ? allowStarterKitEnabledPayload(formData) : allowStarterKitDisabledPayload(),
});
