import { Flex, Toaster, useClipboard, FloraTypography, FloraButton } from '@grupoboticario/flora-react';
import { SocialWhatsappIcon, TwoPiledSquaresIcon } from '@grupoboticario/flora-react-icons';

interface PhoneInfoProps {
  phoneNumber: string;
  hasWhatsAppActive?: boolean;
}

const PhoneInfo = ({ phoneNumber, hasWhatsAppActive }: PhoneInfoProps) => {
  const clipboard = useClipboard();

  const onCopy = () => {
    clipboard.copy(phoneNumber);

    Toaster.notify({
      kind: 'success',
      duration: 3000,
      button: 'Fechar',
      closeWhenClick: true,
      description: 'Telefone copiado',
      origin: 'center-top',
    });
  };

  return (
    <Flex
      data-testid={`phone-number__${phoneNumber}`}
      align="flex-end"
      css={{
        backgroundColor: '$light-dark-1',
        borderRadius: '$medium',
        padding: '$4',
        width: '100%',
      }}
    >
      <Flex direction="column">
        <FloraTypography fontSize="exceptionsAuxiliar">Telefone</FloraTypography>

        <Flex
          align="center"
          css={{
            button: { width: 'unset', height: 'unset' },
          }}
        >
          <FloraTypography fontSize="exceptionsAuxiliar">{phoneNumber}</FloraTypography>

          <FloraButton
            css={{
              color: '$backgroundAltPrimary',
              marginLeft: '$1',
              width: '$5',
              height: '$5',
              borderRadius: '$pill',
            }}
            data-testid="service-center-filter-clear-btn"
            hierarchy="tertiary"
            has="iconOnly"
            icon={<TwoPiledSquaresIcon size="16" aria-label="Copiar texto" />}
            aria-label="Copiar texto"
            onClick={onCopy}
          />
        </Flex>
      </Flex>

      {hasWhatsAppActive && (
        <Flex
          align="center"
          justify="flex-end"
          css={{
            color: '$nonInteractiveAuxiliar',
            fontSize: '$sm',
            marginLeft: 'auto',
            fontWeight: '$medium',
            marginBottom: '$1',
          }}
        >
          <SocialWhatsappIcon size="16" css={{ marginRight: '$1' }} />
          <FloraTypography fontSize="bodySmallShort">WhatsApp ativo</FloraTypography>
        </Flex>
      )}
    </Flex>
  );
};

export { PhoneInfo };
