import { Dispatch, useCallback } from 'react';
import { Toaster } from '@grupoboticario/flora-react';
import { TableLoadFnType, TableLoadFnParams } from '@/components/table/types';
import { TableActionType } from './tableActions';

export const useTableActions = (
  dispatchRef: React.MutableRefObject<Dispatch<TableActionType>>,
  loadData: TableLoadFnType,
) => {
  const dispatchLoadData = useCallback(
    async (params: TableLoadFnParams) => {
      try {
        dispatchRef.current({ type: 'LOAD_DATA_STARTED' });

        Object.keys(params).forEach(
          key => params[key as keyof TableLoadFnParams] === undefined && delete params[key as keyof TableLoadFnParams],
        );

        const response = await loadData(params);
        dispatchRef.current({ type: 'LOAD_DATA_COMPLETED', payload: response });
      } catch {
        dispatchRef.current({ type: 'LOAD_DATA_FAILED' });
        Toaster.notify({
          kind: 'error',
          duration: 60000,
          button: 'Fechar',
          closeWhenClick: true,
          description: 'Um erro ocorreu ao carregar os dados. Tente novamente.',
          origin: 'right-bottom',
        });
      }
    },
    [dispatchRef, loadData],
  );

  return { dispatchLoadData };
};
