import { removeNonDigits } from '@/utils';
import '@/utils/validators/yup';
import { HTTPError } from 'ky';
import { debounce, isNil, omitBy } from 'lodash';
import * as yup from 'yup';
import { PhoneDataForm, RegistrationFormData } from '../types';
import { checkServiceCenterAvailability, getPostalService } from '@/api';

const INPUT_ERROR_MESSAGE = 'Esse campo é obrigatório.';
const EMAIL_ERROR_MESSAGE = 'Por favor, utilize um e-mail válido';
const PHONE_ERROR_MESSAGE = 'Por favor, utilize um celular válido';
const MIN_PHONES_CHARACTERS = 10;

const ASYNC_VALIDATION_TIMEOUT_IN_MS = 500;

const validationCsCodeFunction = async (
  value: string | undefined,
  resolve: (value: unknown) => void,
  serviceCenterId?: string,
) => {
  const isUsed = await checkServiceCenterAvailability(
    omitBy(
      {
        csCode: value,
        _id: serviceCenterId,
      },
      attr => isNil(attr) || attr === '',
    ) as { csCode: string; _id?: string },
  );
  resolve(!isUsed);
};

const validationCsCodeDebounced = debounce(validationCsCodeFunction, ASYNC_VALIDATION_TIMEOUT_IN_MS);

async function validateCep(
  zipCode: string,
  resolve: (value: boolean | yup.ValidationError) => void,
  context: yup.TestContext,
) {
  try {
    await getPostalService(zipCode);
  } catch (error) {
    const errors = error as HTTPError;
    if (errors.response.status === 500) {
      return resolve(
        context.createError({
          message: 'Não foi possível consultar o CEP.',
        }),
      );
    }
    const cepError = context.createError({
      message: 'Não foi possível encontrar o CEP informado.',
    });
    return resolve(cepError);
  }
  return resolve(true);
}
const validateCepDebounced = debounce(validateCep, ASYNC_VALIDATION_TIMEOUT_IN_MS);

const phoneSchema: yup.ObjectSchema<PhoneDataForm> = yup.object({
  key: yup.string().required(),
  number: yup.string().required(INPUT_ERROR_MESSAGE).min(MIN_PHONES_CHARACTERS, PHONE_ERROR_MESSAGE),
  hasWhatsApp: yup.boolean().required(),
});

export const registrationDataSchema: yup.ObjectSchema<RegistrationFormData> = yup.object({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  cpCode: yup.string().required(INPUT_ERROR_MESSAGE).integer(),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  name: yup.string().required(INPUT_ERROR_MESSAGE).lettersAndSpaces(),
  operationalManager: yup.string(),
  csCode: yup
    .string()
    .required(INPUT_ERROR_MESSAGE)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .integer()
    .test({
      message: 'Este código já foi cadastrado. Por favor, digite um código válido.',
      test(value: string) {
        if (Number.isNaN(parseInt(value))) {
          return false;
        }
        return new Promise(resolve => {
          validationCsCodeDebounced(value, resolve, this.parent?.serviceCenterId);
        });
      },
    }),
  street: yup.string().required(INPUT_ERROR_MESSAGE),
  number: yup.string().required(INPUT_ERROR_MESSAGE),
  district: yup.string().required(INPUT_ERROR_MESSAGE),
  state: yup.string().required(INPUT_ERROR_MESSAGE).length(2, 'Digite uma sigla de estado válida'),
  city: yup.string().required(INPUT_ERROR_MESSAGE),
  referencePoint: yup.string(),
  additionalAddressDetails: yup.string(),
  isPrimaryPhone: yup.string(),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  zipCode: yup
    .string()
    .required(INPUT_ERROR_MESSAGE)
    .length(9, 'O CEP precisa ter 8 dígitos.')
    .test({
      test(value: string | undefined) {
        if (!value) {
          return false;
        }
        return new Promise(resolve => {
          validateCepDebounced(removeNonDigits(value), resolve, this);
        });
      },
    }),
  email: yup.string().email(EMAIL_ERROR_MESSAGE),
  phones: yup.array().of(phoneSchema),
  serviceCenterId: yup.string().optional(),
});
