import { Box, Tabs } from '@grupoboticario/flora-react';
import { useEffect, useRef } from 'react';
import {
  BusinessHoursForm,
  CompletedForm,
  DeliveryForm,
  DocumentationForm,
  LockAndLimitForm,
  OrderIncrementForm,
  PaymentMethodsForm,
  PickUpStoreForm,
  RegistrationForm,
  StarterPackForm,
  Wizard,
} from '@/components';
import { ServiceCenterFormState } from '@/types';
import { ServiceCenterFormProvider, useServiceCenterForm } from '@/context';

interface ServiceCenterFormProps {
  initialState?: ServiceCenterFormState;
}

const ServiceCenterFormContent = (): JSX.Element => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { state } = useServiceCenterForm();
  const tabs: { [key: number]: () => JSX.Element } = {
    1: RegistrationForm,
    2: DocumentationForm,
    3: BusinessHoursForm,
    4: OrderIncrementForm,
    5: StarterPackForm,
    6: PickUpStoreForm,
    7: DeliveryForm,
    8: LockAndLimitForm,
    9: PaymentMethodsForm,
    10: CompletedForm,
  };

  const StepToRender = tabs[state.currentStep];

  useEffect(() => {
    containerRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [state.currentStep]);

  return (
    <Tabs
      orientation="vertical"
      defaultValue={`${state.currentStep}`}
      value={`${state.currentStep}`}
      css={{
        margin: '$9 0',
        display: 'grid',
        gridTemplateColumns: '3fr 9fr',
      }}
    >
      <Wizard />
      <Box css={{ p: '$4' }} ref={containerRef}>
        <StepToRender />
      </Box>
    </Tabs>
  );
};

const ServiceCenterForm = ({ initialState }: ServiceCenterFormProps) => (
  <ServiceCenterFormProvider initialState={initialState}>
    <ServiceCenterFormContent />
  </ServiceCenterFormProvider>
);
export { ServiceCenterForm };
