import { Documentation, DocumentationFormData } from '@/types';

export const normalizeDocumentationFormData = (
  documentation: Documentation | undefined,
): DocumentationFormData | undefined => {
  if (!documentation) {
    return undefined;
  }

  return {
    ...documentation,
  };
};
