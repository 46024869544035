import { StarterPack, StarterPackFormData } from '@/types';

export const normalizeStarterPackFormData = (starterPack: StarterPack | undefined): StarterPackFormData | undefined => {
  if (!starterPack) {
    return undefined;
  }

  return {
    ...starterPack,
  };
};
