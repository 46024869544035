import { PhoneInfo } from '@/components';
import { Flex, FloraTypography } from '@grupoboticario/flora-react';

interface PhonesListProps {
  phones: {
    phoneNumber: string;
    hasWhatsAppActive?: boolean;
    isMainNumber?: boolean;
  }[];
}

const PhonesList = ({ phones }: PhonesListProps) => {
  const mainPhoneNumber = phones.find(phone => !!phone.isMainNumber);
  const secondaryPhoneNumbers = phones.filter(phone => !phone.isMainNumber);

  return (
    <>
      <FloraTypography
        fontSize="bodyLargeShort"
        css={{
          display: 'block',
          marginBottom: '$2',
          marginTop: '$4',
          paddingLeft: '$4',
        }}
      >
        Preferencial
      </FloraTypography>
      <PhoneInfo
        phoneNumber={mainPhoneNumber?.phoneNumber as string}
        hasWhatsAppActive={!!mainPhoneNumber?.hasWhatsAppActive}
      />
      {secondaryPhoneNumbers.length > 0 && (
        <>
          <FloraTypography
            data-testid="secondary-phone-numbers"
            fontSize="bodyLargeShort"
            css={{
              display: 'block',
              marginBottom: '$2',
              marginTop: '$8',
              paddingLeft: '$4',
            }}
          >
            Outros
          </FloraTypography>
          <Flex direction="column" gap="$4">
            {secondaryPhoneNumbers.map(phone => (
              <PhoneInfo
                key={`phone-number__${phone.phoneNumber}`}
                phoneNumber={phone.phoneNumber}
                hasWhatsAppActive={phone.hasWhatsAppActive}
              />
            ))}
          </Flex>
        </>
      )}
    </>
  );
};

export { PhonesList };
