import * as yup from 'yup';

const CPF_CNPJ_VALUE_ERROR_MESSAGE = 'CPF ou CNPJ inválido.';
const cpfRegex = /^[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}$/;
const cnpjRegex = /^[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}-?[0-9]{2}$/;

export const cpfCnpjValueTestFn = (value: string | undefined) => {
  const cpfCpnj = value ?? '';
  return cpfRegex.test(cpfCpnj) || cnpjRegex.test(cpfCpnj);
};

export const cpfCnpjValueTest: yup.TestConfig<string | undefined> = {
  name: 'cpf-cpnj-value-test',
  message: CPF_CNPJ_VALUE_ERROR_MESSAGE,
  test: cpfCnpjValueTestFn,
};
