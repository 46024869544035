import { PickUpLimitInfo, PickUpStoreFormData, PickUpStorePeriod } from '@/types';
import * as yup from 'yup';

const INVALID_PERIOD_ERROR_MESSAGE = 'O prazo máximo deve ser maior que o mínimo.';
const MIN_QTY_ERROR_MESSAGE = 'Insira uma quantidade mínima maior que 0.';
const MIN_CHECKBOX_ERROR_MESSAGE = 'Pelo menos 1 item deve ser selecionado.';
const REQUIRED_ERROR_MESSAGE = 'Esse campo é obrigatório.';

const periodRegisterNames = ['hasMinimumPickUpPeriod', 'hasMaximumPickUpPeriod', 'allowPickUpStore'];

const enum PeriodValueInfo {
  DAYS = 1,
  WEEKS = 7,
  MONTHS = 30,
}

const periodValueLabelMap: {
  [key in PickUpStorePeriod]: number;
} = Object.freeze({
  [PickUpStorePeriod.DAYS]: PeriodValueInfo.DAYS,
  [PickUpStorePeriod.WEEKS]: PeriodValueInfo.WEEKS,
  [PickUpStorePeriod.MONTHS]: PeriodValueInfo.MONTHS,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const hasPeriodValue = (path: string, formValues: any) => {
  const keyMap = {
    minimumPickUpPeriodInfo: 'minimumPickUpPeriodInfo',
    maximumPickUpPeriodInfo: 'maximumPickUpPeriodInfo',
    minimumPickUpPeriodQty: 'minimumPickUpPeriodQty',
    maximumPickUpPeriodQty: 'maximumPickUpPeriodQty',
  };
  return !!formValues[
    keyMap[
      path as
        | 'minimumPickUpPeriodInfo'
        | 'maximumPickUpPeriodInfo'
        | 'minimumPickUpPeriodQty'
        | 'maximumPickUpPeriodQty'
    ]
  ];
};

const calculatePeriodInfo = (periodInfo: PickUpStorePeriod, periodQty: number) =>
  periodValueLabelMap[periodInfo] * periodQty;

const periodInfoTest: yup.TestConfig<string | undefined> = {
  name: 'period-info-test',
  message: INVALID_PERIOD_ERROR_MESSAGE,
  test() {
    if (!hasPeriodValue(this.path, this.parent)) {
      return true;
    }
    const { minimumPickUpPeriodInfo, maximumPickUpPeriodInfo, maximumPickUpPeriodQty, minimumPickUpPeriodQty } =
      this.parent;

    const minimumPeriod = calculatePeriodInfo(minimumPickUpPeriodInfo, minimumPickUpPeriodQty);

    const maximumPeriod = calculatePeriodInfo(maximumPickUpPeriodInfo, maximumPickUpPeriodQty);

    return maximumPeriod >= minimumPeriod;
  },
};

export const pickUpStoreFormDataSchema: yup.ObjectSchema<PickUpStoreFormData> = yup.object({
  allowPickUpStore: yup.boolean(),
  pickUpStoreInfo: yup.mixed(),
  hasIdentificationRequirements: yup.boolean(),
  identificationRequirements: yup
    .array()
    .min(1, MIN_CHECKBOX_ERROR_MESSAGE)
    .when('hasIdentificationRequirements', {
      is: false,
      then: schema => schema.min(0),
    }),
  hasProofOfAddress: yup.boolean(),
  proofOfAddress: yup
    .array()
    .min(1, MIN_CHECKBOX_ERROR_MESSAGE)
    .when('hasProofOfAddress', {
      is: false,
      then: schema => schema.min(0),
    }),
  proofOfAddressSpecifications: yup
    .array()
    .min(1, MIN_CHECKBOX_ERROR_MESSAGE)
    .when('hasProofOfAddress', {
      is: false,
      then: schema => schema.min(0),
    }),
  hasScheduleForPickUp: yup.boolean(),
  scheduleForPickUpNote: yup.string().when('hasScheduleForPickUp', {
    is: true,
    then: schema => schema.required(REQUIRED_ERROR_MESSAGE),
    otherwise: schema => schema,
  }),
  hasThirdPartySupport: yup.boolean(),
  thirdPartySupportInfo: yup
    .array()
    .min(1, MIN_CHECKBOX_ERROR_MESSAGE)
    .when('hasThirdPartySupport', {
      is: false,
      then: schema => schema.min(0),
    }),
  hasMinimumPickUpPeriod: yup.boolean(),
  hasMaximumPickUpPeriod: yup.boolean(),
  minimumPickUpPeriodQty: yup
    .string()
    .when(periodRegisterNames, {
      is: true,
      then: schema => schema.required(REQUIRED_ERROR_MESSAGE).test(periodInfoTest),
      otherwise: schema => schema.optional(),
    })
    .when(['hasMinimumPickUpPeriod', 'allowPickUpStore'], {
      is: true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      then: (schema: any) => schema.integerMin(1, MIN_QTY_ERROR_MESSAGE).required(REQUIRED_ERROR_MESSAGE),
      otherwise: schema => schema.optional(),
    }),
  maximumPickUpPeriodQty: yup
    .string()
    .when(periodRegisterNames, {
      is: true,
      then: schema => schema.required(REQUIRED_ERROR_MESSAGE).test(periodInfoTest),
      otherwise: schema => schema.optional(),
    })
    .when(['hasMaximumPickUpPeriod', 'allowPickUpStore'], {
      is: true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      then: (schema: any) => schema.integerMin(1, MIN_QTY_ERROR_MESSAGE).required(REQUIRED_ERROR_MESSAGE),
      otherwise: schema => schema.optional(),
    }),

  minimumPickUpPeriodInfo: yup.mixed<PickUpLimitInfo>().when(periodRegisterNames, {
    is: true,
    then: schema => schema.required(REQUIRED_ERROR_MESSAGE).oneOf(Object.values(PickUpLimitInfo)).test(periodInfoTest),
    otherwise: schema => schema.optional(),
  }),
  maximumPickUpPeriodInfo: yup.mixed<PickUpLimitInfo>().when(periodRegisterNames, {
    is: true,
    then: schema => schema.required(REQUIRED_ERROR_MESSAGE).oneOf(Object.values(PickUpLimitInfo)).test(periodInfoTest),
    otherwise: schema => schema.optional(),
  }),
  note: yup.string().optional(),
});
