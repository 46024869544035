import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { FloraProvider } from '@grupoboticario/flora-react';
import { gbInstitucionalTheme } from '@grupoboticario/flora';
import { ServiceCenter } from './ServiceCenter';
import { CreateServiceCenter } from './components';
import { EditServiceCenter } from './components/edit-service-center';

export const Root = () => {
  return (
    <FloraProvider theme={gbInstitucionalTheme}>
      <BrowserRouter>
        <Routes>
          <Route path="/inicios/centrais-de-servico/" element={<ServiceCenter />} />
          <Route path="/inicios/centrais-de-servico/criar" element={<CreateServiceCenter />} />
          <Route path="/inicios/centrais-de-servico/editar/:id" element={<EditServiceCenter />} />
        </Routes>
      </BrowserRouter>
    </FloraProvider>
  );
};
