import { Franchise, PhoneDataForm, RegistrationFormData, FranchisePhone } from '@/types';

import { formatCep, formatPhoneNumber } from '@/utils';

import { v4 as uuidv4 } from 'uuid';

const normalizePhoneNumbers = (phones: FranchisePhone[]): { primaryPhoneId?: string; phones: PhoneDataForm[] } => {
  const formattedPhones = phones.map(phone => ({
    primary: phone.primary,
    number: formatPhoneNumber(phone.phone) ?? '',
    hasWhatsApp: phone.whatsapp,
    key: uuidv4(),
  }));

  const primaryPhoneId = formattedPhones.find(phone => phone.primary)?.key;

  return {
    primaryPhoneId,
    phones: formattedPhones,
  };
};

export const normalizeRegistrationData = (franchise: Franchise | undefined): RegistrationFormData | undefined => {
  if (!franchise) {
    return undefined;
  }

  const { address } = franchise;

  const { phones, primaryPhoneId } = normalizePhoneNumbers(franchise.contacts.phones ?? []);

  return {
    city: address.city,
    cpCode: franchise.cpCode,
    csCode: address.csCode,
    district: address.landmark,
    name: franchise.name,
    number: address.number,
    state: address.state,
    street: address.street,
    zipCode: formatCep(address.zipCode),
    additionalAddressDetails: address.complement,
    email: franchise.contacts.email,
    operationalManager: franchise.owner,
    referencePoint: address.referencePoint,
    isPrimaryPhone: primaryPhoneId,
    phones,
  } as RegistrationFormData;
};
