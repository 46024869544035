import { FloraButton } from '@grupoboticario/flora-react';
import { MagnifyingGlassIcon } from '@grupoboticario/flora-react-icons';

const SearchIconButton = (): JSX.Element => (
  <FloraButton
    data-testid="service-center-filter-clear-btn"
    hierarchy="tertiary"
    has="iconOnly"
    icon={<MagnifyingGlassIcon />}
    aria-label="Buscar CS"
    type="submit"
  />
);

export { SearchIconButton };
