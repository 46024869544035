import { Pagination } from '@grupoboticario/flora-react';
import { useTable } from '@/components/table/context';

const TablePagination = (): JSX.Element | null => {
  const { tableState, itemsPerPage, hasPagination, onPageChange } = useTable();
  const { totalResults, currentPage } = tableState;
  const includePagination = hasPagination && typeof itemsPerPage === 'number' && typeof totalResults === 'number';

  return includePagination ? (
    <Pagination
      data-testid="table-pagination"
      css={{
        display: 'inline-flex',
        padding: '$4',
      }}
      itemsPerPage={itemsPerPage}
      onChange={onPageChange}
      page={currentPage}
      totalRecords={totalResults}
    />
  ) : null;
};

export { TablePagination };
