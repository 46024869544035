import { saveServiceCenter, events } from '@/api';
import { useServiceCenterForm } from '@/context';
import { StarterPackFormData, StarterKitInstruction } from '@/types';
import { Toaster, Box } from '@grupoboticario/flora-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  FormWrapper,
  FormFieldset,
  InputSwitch,
  FormCheckbox,
  TogglableContent,
  InputNote,
  FormFooter,
  ChipInput,
  GroupCheckbox,
} from '@/components';
import { formatStarterPackFormData, normalizeStarterPackFormData } from '@/components/starter-kit-form/utils';
import { starterPackFormDataSchema } from '@/components/starter-kit-form/validators';
import { starterKitInstructionValueLabelMap } from '@/labels';

const generateDefaultValues = (): StarterPackFormData => ({
  allowStarterKit: false,
  onlyIfStarterKitIsUnavailable: false,
  hasStarterKitInstruction: false,
  hasSpecificProducts: false,
});

const handleDataSubmit = async (data: StarterPackFormData, csId?: string) => {
  if (csId === undefined) {
    throw new Error('Service Center ID não pode ser nulo');
  }

  const formattedData = formatStarterPackFormData(data, csId);

  return saveServiceCenter(formattedData);
};

const starterKitInstructionCheckboxes: {
  label: string;
  value: StarterKitInstruction;
  id: string;
}[] = [
  {
    value: StarterKitInstruction.INFORMAR_INDISPONIBILIDADE,
    label: starterKitInstructionValueLabelMap[StarterKitInstruction.INFORMAR_INDISPONIBILIDADE],
    id: StarterKitInstruction.INFORMAR_INDISPONIBILIDADE,
  },
  {
    value: StarterKitInstruction.ADQUIRIDO_POSTERIORMENTE,
    label: starterKitInstructionValueLabelMap[StarterKitInstruction.ADQUIRIDO_POSTERIORMENTE],
    id: StarterKitInstruction.ADQUIRIDO_POSTERIORMENTE,
  },
];

const StarterPackForm = (): JSX.Element => {
  const { goToNextStep, state, setStarterPack, isEditMode } = useServiceCenterForm();

  const defaultValues = useMemo(
    () => state.formData.starterPack ?? generateDefaultValues(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const methods = useForm<StarterPackFormData>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(starterPackFormDataSchema),
    mode: 'onBlur',
    defaultValues,
  });

  const {
    formState: { isDirty, isSubmitting },
  } = methods;
  useEffect(() => {
    events.emit('UPDATE_CS_DATA', {
      isSubmitting,
    });
  }, [isSubmitting]);

  const shouldSkipRequestOnSubmit = useMemo(
    () => !isDirty && isEditMode && !!state.formData.starterPack,
    [isDirty, isEditMode, state.formData.starterPack],
  );

  const allowStarterKitValue = methods.watch('allowStarterKit');

  const onSubmitStarterKitData = useCallback(
    async (data: StarterPackFormData) => {
      try {
        if (shouldSkipRequestOnSubmit) {
          goToNextStep();
          return;
        }
        const response = await handleDataSubmit(data, state.formData.id);
        setStarterPack(normalizeStarterPackFormData(response.starterPack));
        goToNextStep();
      } catch (e) {
        Toaster.notify({
          kind: 'error',
          description: 'Um erro ocorreu ao salvar esta etapa. Por favor, tente novamente mais tarde.',
          origin: 'right-top',
          duration: 60000,
          button: 'Fechar',
          closeWhenClick: true,
        });
      }
    },
    [goToNextStep, shouldSkipRequestOnSubmit, setStarterPack, state.formData.id],
  );

  return (
    <FormProvider {...methods}>
      <Box data-testId="starter-pack-form" as="form" onSubmit={methods.handleSubmit(onSubmitStarterKitData)}>
        <FormWrapper gap="$2" css={{ padding: '$4 $8' }}>
          <FormFieldset hasDivider={false}>
            <InputSwitch
              label="Permite iniciar com produtos?"
              registerName="allowStarterKit"
              onText="Sim"
              offText="Não"
            />

            <FormCheckbox
              label="Somente se o kit inicial estiver indisponível"
              registerName="onlyIfStarterKitIsUnavailable"
              id="if-starter-kit-unavailable"
              css={{ paddingTop: '$4' }}
              disabled={!allowStarterKitValue}
            />
          </FormFieldset>
        </FormWrapper>
        <FormWrapper title="Condições gerais" gap="$2" css={{ padding: '$4 $8' }}>
          <FormFieldset hasDivider={false}>
            <TogglableContent
              title="Adicionar orientações se o kit inicial não estiver disponível?"
              switchRegisterName="hasStarterKitInstruction"
              disabled={!allowStarterKitValue}
            >
              <GroupCheckbox checkboxes={starterKitInstructionCheckboxes} registerName="starterKitInstruction" />
            </TogglableContent>
          </FormFieldset>

          <FormFieldset>
            <TogglableContent
              title="Produtos específicos?"
              switchRegisterName="hasSpecificProducts"
              disabled={!allowStarterKitValue}
            >
              <ChipInput
                id="specificProducts"
                labelText="Produtos:"
                placeholder="Digitar SKU's separados por ponto e vírgula. Ex: 32913;23131;44442"
                registerName="specificProducts"
                optionalText=""
                data-testid="specific-pproducts-testid"
                disabled={!allowStarterKitValue}
              />
            </TogglableContent>
          </FormFieldset>

          <FormFieldset>
            <InputNote registerName="note" disabled={!allowStarterKitValue} />
          </FormFieldset>
        </FormWrapper>

        <FormFooter />
      </Box>
    </FormProvider>
  );
};

export { StarterPackForm };
