import { FormWrapper } from '@/components';
import { Flex, FloraButton, FloraTypography } from '@grupoboticario/flora-react';
import { CheckCircleIcon, HouseIcon } from '@grupoboticario/flora-react-icons';
import { useNavigate } from 'react-router-dom';

const CompletedForm = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <>
      <FormWrapper gap="$2" css={{ marginTop: '$6' }}>
        <Flex
          direction="column"
          align="center"
          justify="center"
          gapY="$4"
          css={{ height: '400px', gridColumn: 'span 12' }}
        >
          <CheckCircleIcon size="100" color="$success-standard" />
          <FloraTypography as="h6" fontSize="subtitle">
            Central de Serviço cadastrada com sucesso
          </FloraTypography>
          <FloraTypography fontSize="bodyLargeShort">
            Você poderá visualizar as informações cadastradas acessando os detalhes.
          </FloraTypography>
        </Flex>
      </FormWrapper>
      <Flex justify="right">
        <FloraButton
          icon={<HouseIcon size="16" />}
          has="iconLeft"
          data-testid="form-footer-previous-btn"
          type="button"
          onClick={() => navigate('/inicios/centrais-de-servico/')}
        >
          Página inicial
        </FloraButton>
      </Flex>
    </>
  );
};

export { CompletedForm };
