import { ControlledRadioGroup, FormFieldset, FormInput, InputNote, ControlledRadioButtonData } from '@/components/';
import { AccountType, accountTypeValueLabelMap, PaymentMethodsFormData } from '@/types';
import { Box, Flex, Grid } from '@grupoboticario/flora-react';
import { useWatch, useFormContext } from 'react-hook-form';
import { PaymentHeader, PaymentVoucher } from '@/components/payment-methods-form/';
import { formatCpfCnpjInputMask } from '@/utils';

interface AccountTypeOption extends ControlledRadioButtonData {
  value: AccountType;
}

const accountTypeOptions: AccountTypeOption[] = [
  {
    id: 'conta-corrente',
    value: AccountType.CURRENT_ACCOUNT,
    label: accountTypeValueLabelMap[AccountType.CURRENT_ACCOUNT],
  },
  {
    id: 'conta-poupanca',
    value: AccountType.SAVINGS_ACCOUNT,
    label: accountTypeValueLabelMap[AccountType.SAVINGS_ACCOUNT],
  },
];

const DepositAndTransferPayment = (): JSX.Element => {
  const cpfCnpjValue = useWatch({ name: 'depositAndTransferPayment.cpfCnpj' });
  const { getValues } = useFormContext<PaymentMethodsFormData>();
  const accountTypeValue = getValues('depositAndTransferPayment.accountType');

  return (
    <Flex direction="column">
      <PaymentHeader />
      <FormFieldset hasDivider={false}>
        <Grid templateColumns="repeat(12, 1f)" gap="$4">
          <Box css={{ gridColumn: 'span 12' }}>
            <FormInput
              id="bank"
              labelText="Banco:"
              optionalText="Somente o código do banco"
              registerName="depositAndTransferPayment.bank"
              mask="xxxxxxxxxxx"
            />
          </Box>
          <Box css={{ gridColumn: 'span 6' }}>
            <FormInput
              id="bankBranch"
              labelText="Agência:"
              registerName="depositAndTransferPayment.bankBranch"
              mask="xxxxx"
              placeholder="00000"
            />
          </Box>
          <Box css={{ gridColumn: 'span 6' }}>
            <FormInput
              id="accountNumber"
              labelText="Conta:"
              optionalText="Somente números, incluindo dígito verificador"
              registerName="depositAndTransferPayment.accountNumber"
            />
          </Box>
          <Box css={{ gridColumn: '7 / span 6' }}>
            <ControlledRadioGroup
              registerName="depositAndTransferPayment.accountType"
              direction="row"
              options={accountTypeOptions}
              defaultValue={accountTypeValue ?? AccountType.CURRENT_ACCOUNT}
            />
          </Box>
          <Box css={{ gridColumn: 'span 12' }}>
            <FormInput
              id="cpfCnpj"
              labelText="CNPJ / CPF:"
              optionalText="Somente números"
              registerName="depositAndTransferPayment.cpfCnpj"
              mask={formatCpfCnpjInputMask(cpfCnpjValue)}
            />
          </Box>
        </Grid>
      </FormFieldset>
      <PaymentVoucher parentRegisterName="depositAndTransferPayment" />
      <FormFieldset>
        <InputNote registerName="depositAndTransferPayment.note" />
      </FormFieldset>
    </Flex>
  );
};

export { DepositAndTransferPayment };
