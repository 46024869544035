import { ServiceCenterFormActionType } from '@/actions';
import { ServiceCenterFormState, getDefaultServiceCenterFormValue } from '@/types';

export const getServiceCenterFormInitialState = (): ServiceCenterFormState => ({
  currentStep: 1,
  formData: getDefaultServiceCenterFormValue(),
});

export type ServiceCenterFormReducerType = (
  state: ServiceCenterFormState,
  action: ServiceCenterFormActionType,
) => ServiceCenterFormState;

export const serviceCenterFormReducer: ServiceCenterFormReducerType = (state, action): ServiceCenterFormState => {
  switch (action.type) {
    case 'SET_WIZARD_STEP':
      return {
        ...state,
        currentStep: action.payload,
      };

    case 'INCREMENT_WIZARD_STEP':
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };

    case 'DECREMENT_WIZARD_STEP':
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };

    case 'SET_SERVICE_CENTER':
      return {
        ...state,
        formData: action.payload,
      };

    case 'SET_SERVICE_CENTER_ID':
      return {
        ...state,
        formData: {
          ...state.formData,
          id: action.payload,
        },
      };

    case 'SET_REGISTRATION_DATA':
      return {
        ...state,
        formData: {
          ...state.formData,
          registrationData: action.payload,
        },
      };

    case 'SET_BUSINESS_HOURS':
      return {
        ...state,
        formData: {
          ...state.formData,
          businessHours: action.payload,
        },
      };

    case 'SET_ORDER_INCREMENT':
      return {
        ...state,
        formData: {
          ...state.formData,
          orderIncrement: action.payload,
        },
      };
    case 'SET_STARTER_PACK':
      return {
        ...state,
        formData: {
          ...state.formData,
          starterPack: action.payload,
        },
      };
    case 'SET_PICK_UP_STORE':
      return {
        ...state,
        formData: {
          ...state.formData,
          pickUpInStore: action.payload,
        },
      };

    case 'SET_DELIVERY':
      return {
        ...state,
        formData: {
          ...state.formData,
          delivery: action.payload,
        },
      };

    case 'SET_LOCK_AND_LIMIT':
      return {
        ...state,
        formData: {
          ...state.formData,
          lockAndLimit: action.payload,
        },
      };

    case 'SET_PAYMENT_METHODS':
      return {
        ...state,
        formData: {
          ...state.formData,
          paymentMethods: action.payload,
        },
      };
    case 'SET_DOCUMENTATION':
      return {
        ...state,
        formData: {
          ...state.formData,
          documentation: action.payload,
        },
      };
    default:
      throw new Error(`Tipo de ação inválida`);
  }
};
