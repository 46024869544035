import { Box, CSS, Input } from '@grupoboticario/flora-react';
import { useFormContext } from 'react-hook-form';

interface InputNoteProps {
  css?: CSS;
  labelText?: string;
  optionalText?: string;
  registerName: string;
  disabled?: boolean;
}

const InputNote = ({
  css,
  labelText = 'Observação:',
  optionalText = 'escreva a observação',
  registerName,
  disabled = false,
}: InputNoteProps): JSX.Element => {
  const { register } = useFormContext();

  return (
    <Box
      css={{
        '& div, & textarea': {
          height: '100px',
        },
        ...css,
      }}
    >
      <Input
        as="textarea"
        id="textareaField"
        data-testid="textarea-field"
        labelText={labelText}
        optionalText={optionalText}
        css={{
          resize: 'none',
        }}
        disabled={disabled}
        {...register(registerName)}
      />
    </Box>
  );
};

export { InputNote };
