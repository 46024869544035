import { PixKey, PixPaymentFormData } from '@/types';
import { cpfCnpjValueTest } from '@/utils';
import * as yup from 'yup';
import { requestPaymentVoucherFormDataSchema } from './requestPaymentVoucherFormDataSchema';
import { paymentFormDataSchema } from './paymentFormDataSchema';

const REQUIRED_ERROR_MESSAGE = 'Esse campo é obrigatório.';
const MIN_RANDOM_KEY_CHARACTERS_MESSAGE = 'Uma chave aleatória Pix tem 32 caracteres. Insira um formato válido.';

const generatePixValueSchema = (pixKeyType: PixKey) =>
  yup.string().when(['keyType', 'isEnabled'], {
    is: (type: PixKey, isEnabled: boolean) => isEnabled && type === pixKeyType,
    then: schema => {
      const requiredSchema = schema.required(REQUIRED_ERROR_MESSAGE);

      if (pixKeyType === PixKey.CPF_CNPJ) {
        return requiredSchema.test(cpfCnpjValueTest);
      }

      if (pixKeyType === PixKey.EMAIL) {
        return requiredSchema.email();
      }

      if (pixKeyType === PixKey.RANDOM) {
        return requiredSchema.min(36, MIN_RANDOM_KEY_CHARACTERS_MESSAGE);
      }

      return requiredSchema;
    },
    otherwise: schema => schema.optional(),
  });

export const pixPaymentFormDataSchema = yup.object<PixPaymentFormData>().shape({
  ...requestPaymentVoucherFormDataSchema.fields,
  ...paymentFormDataSchema.fields,
  keyType: yup.mixed<PixKey>().when('isEnabled', {
    is: true,
    then: schema => schema.oneOf(Object.values(PixKey)).required(REQUIRED_ERROR_MESSAGE),
    otherwise: schema => schema.optional(),
  }),
  emailValue: generatePixValueSchema(PixKey.EMAIL),
  cpfCnpjValue: generatePixValueSchema(PixKey.CPF_CNPJ),
  phoneNumberValue: generatePixValueSchema(PixKey.PHONE_NUMBER),
  randomValue: generatePixValueSchema(PixKey.RANDOM),
});
