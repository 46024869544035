import { fetcher } from '@/api';

interface CheckServiceCenterAvailabilityWithCode {
  csCode: string;
  _id?: string;
}

interface CheckServiceCenterAvailabilityWithName {
  name: string;
}

export type CheckServiceCenterAvailabilityRequestType =
  | CheckServiceCenterAvailabilityWithCode
  | CheckServiceCenterAvailabilityWithName;

export async function checkServiceCenterAvailability(
  queryParams: CheckServiceCenterAvailabilityRequestType,
): Promise<boolean> {
  return fetcher.get<boolean>('service-centers/check', {
    searchParams: { ...queryParams },
  });
}
