import {
  BankSlipPayment,
  CashPayment,
  CreditCardPayment,
  DebitCardPayment,
  DepositAndTransferPayment,
  OnlineCardPaymentType,
  PixPayment,
} from './serviceCenter';
import {
  PaymentLocationInfo,
  SendPaymentVoucher,
  PaymentVoucherSpecification,
  PixKey,
  AccountType,
} from './serviceEnum';

export type BankSlipPaymentFormData = BankSlipPayment;

export type CashPaymentFormData = CashPayment;

export type CreditCardPaymentFormData = CreditCardPayment;

export type DebitCardPaymentFormData = DebitCardPayment;

export type DepositAndTransferPaymentFormData = DepositAndTransferPayment;

export type OnlineCardPaymentFormData = OnlineCardPaymentType;

export interface PixPaymentFormData extends Omit<PixPayment, 'pixKeyValue'> {
  emailValue?: string;
  cpfCnpjValue?: string;
  phoneNumberValue?: string;
  randomValue?: string;
}

export interface PaymentMethodsFormData {
  bankSlipPayment: BankSlipPaymentFormData;
  cashPayment: CashPaymentFormData;
  creditCardPayment: CreditCardPaymentFormData;
  debitCardPayment: DebitCardPaymentFormData;
  depositAndTransferPayment: DepositAndTransferPaymentFormData;
  onlineCardPayment: OnlineCardPaymentFormData;
  pixPayment: PixPaymentFormData;
}

export const paymentLocationValueLabelMap: {
  [key in PaymentLocationInfo]: string;
} = Object.freeze({
  [PaymentLocationInfo.IN_PERSON]: 'Pagamento presencial',
  [PaymentLocationInfo.ON_DELIVERY]: 'Pagamento na entrega',
});

export const sendPaymentVouchersValueLabelMap: {
  [key in SendPaymentVoucher]: string;
} = Object.freeze({
  [SendPaymentVoucher.OPERATIONAL_MANAGER]: 'Enviar dados completos para líder operacional',
  [SendPaymentVoucher.WHATSAPP]: 'Enviar por Whatsapp',
  [SendPaymentVoucher.EMAIL]: 'Enviar por e-mail',
});

export const paymentVoucherSpecificationValueLabelMap: {
  [key in PaymentVoucherSpecification]: string;
} = Object.freeze({
  [PaymentVoucherSpecification.INCLUDE_ORDER_INFO]: 'Incluir dados do pedido',
  [PaymentVoucherSpecification.INCLUDE_RESELLER_INFO]: 'Incluir dados da IR',
});

export const pixKeyTypeValueLabelMap: {
  [key in PixKey]: string;
} = Object.freeze({
  [PixKey.CPF_CNPJ]: 'CPF ou CNPJ',
  [PixKey.PHONE_NUMBER]: 'Celular',
  [PixKey.EMAIL]: 'E-mail',
  [PixKey.RANDOM]: 'Aleatória',
});

export const accountTypeValueLabelMap: {
  [key in AccountType]: string;
} = Object.freeze({
  [AccountType.CURRENT_ACCOUNT]: 'Corrente',
  [AccountType.SAVINGS_ACCOUNT]: 'Poupança',
});
