import { Delivery, DeliveryFormData } from '@/types';
import { formatCurrency } from '@/utils';

export const normalizeDeliveryFormData = (delivery: Delivery | undefined): DeliveryFormData | undefined => {
  if (!delivery) {
    return undefined;
  }

  const formatSpecialConditionsCurrencyFields = () => {
    const { specialConditions } = { ...delivery };

    const hasSpecialConditions = specialConditions && specialConditions.length > 0;

    if (!hasSpecialConditions) {
      return specialConditions;
    }

    const formattedSpecialConditions = specialConditions.map(condition => {
      return {
        deliveryCost: formatCurrency(Number(condition.deliveryCost)),
        minimumPurchaseValue: formatCurrency(Number(condition.minimumPurchaseValue)),
        hasFreeDelivery: condition.hasFreeDelivery,
      };
    });

    return formattedSpecialConditions;
  };

  return {
    ...delivery,
    deliveryCost: formatCurrency(Number(delivery.deliveryCost)),
    specialConditions: formatSpecialConditionsCurrencyFields(),
  };
};
