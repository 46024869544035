import {
  PaymentMethodsFormData,
  PixPaymentFormData,
  AcceptsInstallmentPayments,
  InstallmentConditionData,
  PaymentMethods,
  PixKey,
} from '@/types';
import { formatCpfOrCnpj, formatCurrency, formatPhoneNumber } from '@/utils';

const pixKeyToAttrMap = new Map<
  PixKey,
  keyof Pick<PixPaymentFormData, 'cpfCnpjValue' | 'emailValue' | 'phoneNumberValue' | 'randomValue'>
>([
  [PixKey.CPF_CNPJ, 'cpfCnpjValue'],
  [PixKey.EMAIL, 'emailValue'],
  [PixKey.PHONE_NUMBER, 'phoneNumberValue'],
  [PixKey.RANDOM, 'randomValue'],
]);

const formatWhatsAppVoucher = (phoneNumber: string | undefined) => {
  return {
    paymentVoucherWhatsapp: formatPhoneNumber(phoneNumber) ?? undefined,
  };
};

const formatPixValue = (keyType: PixKey, value: string | undefined) => {
  if (keyType === PixKey.CPF_CNPJ) {
    return formatCpfOrCnpj(value);
  }
  if (keyType === PixKey.PHONE_NUMBER) {
    return formatPhoneNumber(value);
  }
  return value;
};

const formatPixPayment = (data: PaymentMethods['pixPayment']) => {
  if (data.keyType === undefined) {
    return data;
  }

  return {
    ...data,
    [pixKeyToAttrMap.get(data.keyType) as string]: formatPixValue(data.keyType, data.pixKeyValue),
  };
};

const formatInstallmentPaymentsFields = (installment: AcceptsInstallmentPayments) => {
  if (!installment.hasInstallmentOptions) {
    return {
      hasInstallmentOptions: false,
    };
  }
  const installmentConditions = installment.installmentOptions as InstallmentConditionData[];

  const hasInstallmentConditions = installmentConditions && installmentConditions.length > 0;

  if (!hasInstallmentConditions && !installment.minimumInstallmentValue) {
    return installment;
  }

  const formattedInstallmentOptions = installmentConditions.map(installmentOption => {
    return {
      condition: installmentOption.condition,
      value: formatCurrency(Number(installmentOption.value)),
      maximumInstallments: installmentOption.maximumInstallments,
    };
  });

  return {
    ...installment,
    installmentOptions: formattedInstallmentOptions,
    minimumInstallmentValue: formatCurrency(Number(installment.minimumInstallmentValue)),
  };
};

export const normalizePaymentMethodsFormData = (
  paymentMethods: PaymentMethods | undefined,
): PaymentMethodsFormData | undefined => {
  return paymentMethods
    ? {
        ...paymentMethods,
        pixPayment: {
          ...formatPixPayment(paymentMethods.pixPayment),
          ...formatWhatsAppVoucher(paymentMethods.pixPayment.paymentVoucherWhatsapp),
        },
        bankSlipPayment: {
          ...paymentMethods.bankSlipPayment,
          ...formatInstallmentPaymentsFields(paymentMethods.bankSlipPayment),
        },
        creditCardPayment: {
          ...paymentMethods.creditCardPayment,
          ...formatInstallmentPaymentsFields(paymentMethods.creditCardPayment),
        },
        depositAndTransferPayment: {
          ...paymentMethods.depositAndTransferPayment,
          ...formatWhatsAppVoucher(paymentMethods.depositAndTransferPayment.paymentVoucherWhatsapp),
          cpfCnpj: formatCpfOrCnpj(paymentMethods.depositAndTransferPayment.cpfCnpj),
        },
      }
    : undefined;
};
