import { useCallback, useEffect, useMemo } from 'react';
import { FormWrapper, FormFieldset, FormFooter, TogglableContent } from '@/components/';
import { Box, Toaster } from '@grupoboticario/flora-react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  AtIcon,
  BoxArrowDownIcon,
  CardIcon,
  DollarSignCircleIcon,
  ReceiptIcon,
} from '@grupoboticario/flora-react-icons';
import {
  BankSlipPayment,
  CashPayment,
  CreditCardPayment,
  DebitCardPayment,
  DepositAndTransferPayment,
  OnlineCardPayment,
  PixPayment,
} from '@/components/payment-methods-form/';
import { formatPaymentMethodsFormData, normalizePaymentMethodsFormData } from '@/components/payment-methods-form/utils';
import { PaymentMethodsFormData } from '@/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { paymentMethodsFormDataSchema } from '@/components/payment-methods-form/validators/';
import { events, saveServiceCenter } from '@/api';
import { useServiceCenterForm } from '@/context';

const generateDefaultValues = (): PaymentMethodsFormData => ({
  bankSlipPayment: {
    isEnabled: false,
    hasInstallmentOptions: false,
    hasMinimumInstallmentValue: false,
  },
  cashPayment: { isEnabled: false, paymentLocations: [] },
  creditCardPayment: {
    isEnabled: false,
    hasInstallmentOptions: false,
    hasMinimumInstallmentValue: false,
    paymentLocations: [],
  },
  debitCardPayment: { isEnabled: false, paymentLocations: [] },
  depositAndTransferPayment: {
    isEnabled: false,
    requestsPaymentVoucher: false,
    sendTo: [],
    specifications: [],
  },
  onlineCardPayment: { isEnabled: false },
  pixPayment: {
    isEnabled: false,
    requestsPaymentVoucher: false,
    sendTo: [],
    specifications: [],
  },
});

const handlePaymentMethodsFormDataSubmit = async (data: PaymentMethodsFormData, csId?: string) => {
  if (csId === undefined) {
    throw new Error('Service Center ID não pode ser nulo');
  }

  const formattedData = formatPaymentMethodsFormData(data, csId);
  return saveServiceCenter(formattedData);
};

const PaymentMethodsForm = (): JSX.Element => {
  const { goToNextStep, state, setPaymentMethods, isEditMode } = useServiceCenterForm();

  const defaultValues = useMemo(
    () => state.formData.paymentMethods ?? generateDefaultValues(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const methods = useForm<PaymentMethodsFormData>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(paymentMethodsFormDataSchema),
    mode: 'onBlur',
    defaultValues,
  });

  const {
    formState: { isDirty, isSubmitting },
  } = methods;

  const shouldSkipRequestOnSubmit = useMemo(
    () => !isDirty && isEditMode && !!state.formData.paymentMethods,
    [isDirty, isEditMode, state.formData.paymentMethods],
  );

  useEffect(() => {
    events.emit('UPDATE_CS_DATA', {
      isSubmitting,
    });
  }, [isSubmitting]);

  const onSubmitPaymentMethodsData = useCallback(
    async (data: PaymentMethodsFormData) => {
      try {
        if (shouldSkipRequestOnSubmit) {
          goToNextStep();
          return;
        }
        const response = await handlePaymentMethodsFormDataSubmit(data, state.formData.id);
        setPaymentMethods(normalizePaymentMethodsFormData(response.paymentMethods));

        goToNextStep();
      } catch (e) {
        Toaster.notify({
          kind: 'error',
          description: 'Um erro ocorreu ao salvar esta etapa. Por favor, tente novamente mais tarde.',
          origin: 'right-top',
          duration: 60000,
          button: 'Fechar',
          closeWhenClick: true,
        });
      }
    },
    [goToNextStep, setPaymentMethods, shouldSkipRequestOnSubmit, state.formData.id],
  );

  const paymentEnabledValues = methods.watch([
    'bankSlipPayment.isEnabled',
    'cashPayment.isEnabled',
    'creditCardPayment.isEnabled',
    'debitCardPayment.isEnabled',
    'depositAndTransferPayment.isEnabled',
    'onlineCardPayment.isEnabled',
    'pixPayment.isEnabled',
  ]);

  return (
    <FormProvider {...methods}>
      <Box as="form" onSubmit={methods.handleSubmit(onSubmitPaymentMethodsData, err => console.log(err))}>
        <FormWrapper>
          <FormFieldset hasDivider={false}>
            <TogglableContent
              switchRegisterName="bankSlipPayment.isEnabled"
              title="Boleto"
              leftIcon={<ReceiptIcon size="24" />}
              isCollapsible
            >
              <BankSlipPayment />
            </TogglableContent>
          </FormFieldset>
        </FormWrapper>

        <FormWrapper>
          <FormFieldset hasDivider={false}>
            <TogglableContent
              switchRegisterName="creditCardPayment.isEnabled"
              title="Cartão de crédito"
              leftIcon={<CardIcon size="24" />}
              isCollapsible
            >
              <CreditCardPayment />
            </TogglableContent>
          </FormFieldset>
        </FormWrapper>

        <FormWrapper>
          <FormFieldset hasDivider={false}>
            <TogglableContent
              switchRegisterName="debitCardPayment.isEnabled"
              title="Cartão de débito"
              leftIcon={<CardIcon size="24" />}
              isCollapsible
            >
              <DebitCardPayment />
            </TogglableContent>
          </FormFieldset>
        </FormWrapper>

        <FormWrapper>
          <FormFieldset hasDivider={false}>
            <TogglableContent
              switchRegisterName="pixPayment.isEnabled"
              title="Pix"
              leftIcon={<CardIcon size="24" />}
              isCollapsible
            >
              <PixPayment />
            </TogglableContent>
          </FormFieldset>
        </FormWrapper>

        <FormWrapper>
          <FormFieldset hasDivider={false}>
            <TogglableContent
              switchRegisterName="depositAndTransferPayment.isEnabled"
              title="Depósito e transferência"
              leftIcon={<BoxArrowDownIcon size="24" />}
              isCollapsible
            >
              <DepositAndTransferPayment />
            </TogglableContent>
          </FormFieldset>
        </FormWrapper>

        <FormWrapper>
          <FormFieldset hasDivider={false}>
            <TogglableContent
              switchRegisterName="cashPayment.isEnabled"
              title="Dinheiro"
              leftIcon={<DollarSignCircleIcon size="24" />}
              isCollapsible
            >
              <CashPayment />
            </TogglableContent>
          </FormFieldset>
        </FormWrapper>

        <FormWrapper>
          <FormFieldset hasDivider={false}>
            <TogglableContent
              switchRegisterName="onlineCardPayment.isEnabled"
              title="Cartão on-line"
              leftIcon={<AtIcon size="24" />}
              isCollapsible
            >
              <OnlineCardPayment />
            </TogglableContent>
          </FormFieldset>
        </FormWrapper>

        <FormFooter isLastStep isNextButtonDisabled={!paymentEnabledValues.includes(true)} />
      </Box>
    </FormProvider>
  );
};

export { PaymentMethodsForm };
