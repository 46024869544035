import { fetcher } from '@/api/fetcher';
import { SaveServiceCenterRequestType, ServiceCenter } from '@/types';

export async function saveServiceCenter(payload: SaveServiceCenterRequestType): Promise<ServiceCenter> {
  const response = await fetcher.post('service-centers', {
    json: { ...payload },
  });

  return response.json();
}
