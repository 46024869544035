import * as yup from 'yup';

yup.addMethod(yup.string, 'or', function or(schemas, msg) {
  return this.test({
    name: 'or',
    message: msg ?? 'Por favor entre com um valor válido.',
    test: value => {
      if (Array.isArray(schemas) && schemas.length > 1) {
        const resee = schemas.map(schema => schema.isValidSync(value));
        return resee.some(res => res);
      }
      throw new TypeError('Schemas is not correct array schema');
    },
    exclusive: false,
  });
});

yup.addMethod(yup.string, 'integer', function integer() {
  return this.matches(/^\d+$/, 'Por favor, utilize somente números');
});

yup.addMethod(yup.string, 'integerMin', function integerMin(minimumValue: number, msg?: string) {
  return this.test({
    name: 'integerMin',
    message: msg ?? `O valor mínimo é ${minimumValue}`,
    test: value => {
      const numberValue = Number.parseInt(value as string);
      return Number.isNaN(numberValue) ? false : numberValue >= minimumValue;
    },
    exclusive: false,
  });
});

yup.addMethod(yup.string, 'lettersAndSpaces', function lettersAndSpaces() {
  return this.matches(/^[a-zA-ZÀ-ú\s]*$/, 'Por favor, utilize somente letras');
});
