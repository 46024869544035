import React, { useCallback, useState } from 'react';
import { Flex, FloraButton, Input, InputProps, Tag } from '@grupoboticario/flora-react';
import { Controller, useFormContext } from 'react-hook-form';
import { CheckSingleIcon, CrossIcon } from '@grupoboticario/flora-react-icons';
import { removeNonDigits } from '@/utils';

interface ChipInputProps extends InputProps {
  registerName: string;
  isTextInput?: boolean;
}

export const ChipInput = ({ registerName, isTextInput = false, ...rest }: ChipInputProps): JSX.Element => {
  const { control, setValue, getValues, getFieldState, trigger } = useFormContext();
  const [inputValue, setInputValue] = useState<string>('');
  const fieldState = getFieldState(registerName);
  const hasError = !!fieldState.error;

  const updateValues = useCallback(
    (text: string) => {
      const valuesToAdd = text
        .split(';')
        .map(value => value.trim())
        .filter(value => value !== '');
      const prevValue = getValues(registerName) ?? [];
      const newValue = new Set([...prevValue, ...valuesToAdd]);

      setValue(registerName, Array.from(newValue), { shouldDirty: true });
      setInputValue('');
      trigger(registerName);
    },
    [getValues, registerName, setValue, trigger],
  );

  const onInputTextChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const text = evt.target.value ?? '';
      if ((text ?? '').includes(';')) {
        updateValues(text);
      } else {
        setInputValue(text);
      }
    },
    [updateValues],
  );

  const onRemoveItem = useCallback(
    (value: string) => {
      const prevValue = getValues(registerName) ?? [];
      const newValue = prevValue.filter((v: string) => value !== v);
      setValue(registerName, newValue, { shouldDirty: true });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getValues, setValue],
  );

  return (
    <Controller
      name={registerName}
      control={control}
      render={({ field: { value } }) => (
        <Flex direction="column">
          <Input
            {...rest}
            data-test-id="chip-input-testid"
            value={isTextInput ? inputValue : removeNonDigits(inputValue)}
            onChange={onInputTextChange}
            data-valid={fieldState.isDirty && !fieldState.error}
            invalidText={fieldState.error?.message}
            invalid={!!fieldState.error}
            rightIcon={
              <FloraButton
                data-testid="chip-input-submit"
                hierarchy="tertiary"
                has="iconOnly"
                icon={<CheckSingleIcon />}
                aria-label="Inserir valores"
                onClick={() => updateValues(inputValue)}
              />
            }
          />
          <Flex
            wrap="wrap"
            gap="$2"
            css={{
              marginTop: hasError && !!value?.length ? '$3' : undefined,
            }}
          >
            {(value ?? []).map((text: string) => (
              <Tag
                key={`tag-${text}`}
                size="medium"
                variant="assorted9"
                rightIcon={
                  <Flex
                    align="center"
                    data-testid={`remove-${text}`}
                    css={{ cursor: 'pointer' }}
                    onClick={() => onRemoveItem(text)}
                  >
                    <CrossIcon size="12" />
                  </Flex>
                }
              >
                {text}
              </Tag>
            ))}
          </Flex>
        </Flex>
      )}
    />
  );
};
