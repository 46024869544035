import { FormFieldset, InputNote } from '@/components/';
import { Flex } from '@grupoboticario/flora-react';
import { PaymentHeader } from '@/components/payment-methods-form';

const OnlineCardPayment = (): JSX.Element => (
  <Flex direction="column">
    <PaymentHeader hasTitle={false} />
    <FormFieldset hasDivider={false}>
      <InputNote registerName="onlineCardPayment.note" />
    </FormFieldset>
  </Flex>
);

export { OnlineCardPayment };
