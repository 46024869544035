import { PaymentMethodsFormData } from '@/types';
import * as yup from 'yup';
import { bankSlipPaymentFormDataSchema } from './bankSlipPaymentFormDataSchema';
import { cashPaymentFormDataSchema } from './cashPaymentFormDataSchema';
import { creditCardPaymentFormDataSchema } from './creditCardPaymentFormDataSchema';
import { debitCardPaymentFormDataSchema } from './debitCardPaymentFormDataSchema';
import { depositAndTransferPaymentFormDataSchema } from './depositAndTransferPaymentFormDataSchema';
import { onlineCardPaymentFormDataSchema } from './onlineCardPaymentFormDataSchema';
import { pixPaymentFormDataSchema } from './pixPaymentFormDataSchema';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const paymentMethodsFormDataSchema: yup.ObjectSchema<PaymentMethodsFormData> = yup.object({
  bankSlipPayment: bankSlipPaymentFormDataSchema,
  cashPayment: cashPaymentFormDataSchema,
  creditCardPayment: creditCardPaymentFormDataSchema,
  debitCardPayment: debitCardPaymentFormDataSchema,
  depositAndTransferPayment: depositAndTransferPaymentFormDataSchema,
  onlineCardPayment: onlineCardPaymentFormDataSchema,
  pixPayment: pixPaymentFormDataSchema,
});
