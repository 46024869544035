import { FormFieldset, FormInputCurrency, TogglableContent } from '@/components';
import { useFormContext } from 'react-hook-form';

interface InputMinMaxSectionProps {
  disabled: boolean;
}

const InputMinMaxSection = ({ disabled = false }: InputMinMaxSectionProps): JSX.Element => {
  const { trigger } = useFormContext();

  return (
    <>
      <FormFieldset>
        <TogglableContent title="Valor mínimo?" switchRegisterName="hasMinimumValue" disabled={disabled}>
          <FormInputCurrency
            data-testid="minimum-value"
            registerName="minimumValue"
            labelText="Valor:"
            placeholder="R$ 0,00"
            id="minimumValue"
            onBlur={() => {
              trigger('maximumValue');
            }}
            disabled={disabled}
          />
        </TogglableContent>
      </FormFieldset>
      <FormFieldset>
        <TogglableContent title="Valor máximo?" switchRegisterName="hasMaximumValue" disabled={disabled}>
          <FormInputCurrency
            data-testid="maximum-value"
            registerName="maximumValue"
            labelText="Valor:"
            placeholder="R$ 0,00"
            id="maximumValue"
            onBlur={() => {
              trigger('minimumValue');
            }}
            disabled={disabled}
          />
        </TogglableContent>
      </FormFieldset>
    </>
  );
};

export { InputMinMaxSection };
