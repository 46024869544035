import * as yup from 'yup';
import { CreditCardPaymentFormData } from '@/types';
import { paymentFormDataSchema } from './paymentFormDataSchema';
import { paymentLocationsFormDataSchema } from './paymentLocationsFormDataSchema';
import { acceptsInstallmentsFormDataSchema } from './acceptsInstallmentsFormDataSchema';

export const creditCardPaymentFormDataSchema = yup.object<CreditCardPaymentFormData>().shape({
  ...paymentFormDataSchema.fields,
  ...paymentLocationsFormDataSchema.fields,
  ...acceptsInstallmentsFormDataSchema.fields,
});
