import { StarterPackFormData } from '@/types';

import * as yup from 'yup';

const REQUIRED_ERROR_MESSAGE = 'Esse campo é obrigatório.';
const PRODUCTS_MIN_ERROR_MESSAGE = 'Insira pelo menos um produto.';
const MIN_CHECKBOX_ERROR_MESSAGE = 'Pelo menos 1 item deve ser selecionado.';

export const starterPackFormDataSchema: yup.ObjectSchema<StarterPackFormData> = yup.object({
  allowStarterKit: yup.boolean().required(),
  onlyIfStarterKitIsUnavailable: yup.boolean().when(['allowStarterKit'], {
    is: true,
    then: schema => schema.required(),
    otherwise: schema => schema.optional(),
  }),
  hasStarterKitInstruction: yup.boolean().required(),
  starterKitInstruction: yup
    .array()
    .min(1, MIN_CHECKBOX_ERROR_MESSAGE)
    .when('hasStarterKitInstruction', {
      is: false,
      then: schema => schema.min(0),
    }),

  specificProducts: yup.array().when(['hasSpecificProducts'], {
    is: true,
    then: schema =>
      schema.of(yup.string().required()).optional().required(REQUIRED_ERROR_MESSAGE).min(1, PRODUCTS_MIN_ERROR_MESSAGE),
    otherwise: schema => schema.optional().nullable(),
  }),
  hasSpecificProducts: yup.boolean().required(),
  note: yup.string().optional(),
});
