import { Flex, FloraTypography } from '@grupoboticario/flora-react';

const TableEmptySate = (): JSX.Element => (
  <Flex data-testid="table-empty-state" direction="column" align="center" justify="center" css={{ marginTop: '$20' }}>
    <FloraTypography as="h6" fontSize="exceptionsAuxiliar" css={{ marginTop: '$5', color: '$dark-light' }}>
      Nenhuma informação correspondeu à sua busca
    </FloraTypography>

    <FloraTypography fontSize="bodyLargeStandard" css={{ marginTop: '$4', color: '$nonInteractiveAuxiliar' }}>
      Por favor, tente realizar uma nova busca.
    </FloraTypography>
  </Flex>
);

export { TableEmptySate };
