import { useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Box, Flex, FloraButton, Grid } from '@grupoboticario/flora-react';
import { CrossIcon, PlusIcon } from '@grupoboticario/flora-react-icons';
import {
  DropdownItemsProps,
  FormFieldset,
  TogglableContent,
  ControlledSelect,
  FormInput,
  FormInputCurrency,
} from '@/components/';
import { InstallmentCondition, PaymentMethodsFormData } from '@/types';

interface DropdownItem extends DropdownItemsProps {
  value: InstallmentCondition;
}

const getDropdownItems = (registerName: string): DropdownItem[] => [
  {
    key: `${registerName}-none`,
    value: InstallmentCondition.NONE,
    name: 'Nenhum',
  },
  {
    key: `${registerName}-purchases-until`,
    value: InstallmentCondition.PURCHASES_UNTIL,
    name: 'Compras até',
  },
  {
    key: `${registerName}-purchases-from`,
    value: InstallmentCondition.PURCHASES_FROM,
    name: 'Compras a partir',
  },
  {
    key: `${registerName}-independent`,
    value: InstallmentCondition.INDEPENDENT,
    name: 'Independente do valor',
  },
];

interface InstallmentOptionsProps {
  parentRegisterName: keyof Pick<PaymentMethodsFormData, 'creditCardPayment' | 'bankSlipPayment'>;
  acceptInstallmentsLabel: string;
}

const InstallmentOptions = ({ parentRegisterName, acceptInstallmentsLabel }: InstallmentOptionsProps): JSX.Element => {
  const { control } = useFormContext<PaymentMethodsFormData>();
  const { append, remove, fields } = useFieldArray({
    name: `${parentRegisterName}.installmentOptions`,
    control,
  });

  const installmentOptionsValues = useWatch({
    name: `${parentRegisterName}.installmentOptions`,
  });

  useEffect(() => {
    if (!fields.length) {
      append({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormFieldset>
        <TogglableContent
          switchRegisterName={`${parentRegisterName}.hasInstallmentOptions`}
          title={acceptInstallmentsLabel}
        >
          <Box data-testid="installment-option-list">
            {fields.map((field, idx) => (
              <Grid templateColumns="5fr 3fr 4fr $12" key={field.id} gap="$4">
                <Box>
                  <ControlledSelect
                    registerName={`${parentRegisterName}.installmentOptions.${idx}.condition`}
                    dropdownItems={getDropdownItems(parentRegisterName)}
                    id={`${parentRegisterName}.${idx}-condition`}
                    labelText="Condição:"
                    optionalText="Selecione"
                  />
                </Box>
                <Box>
                  <FormInputCurrency
                    id={`${parentRegisterName}.${idx}-value`}
                    registerName={`${parentRegisterName}.installmentOptions.${idx}.value`}
                    data-testid={`${parentRegisterName}.${idx}-value-testid`}
                    labelText="Valor:"
                    disabled={installmentOptionsValues?.[idx]?.condition === InstallmentCondition.INDEPENDENT}
                  />
                </Box>
                <Box>
                  <FormInput
                    id={`${parentRegisterName}.${idx}-maximum-installments`}
                    registerName={`${parentRegisterName}.installmentOptions.${idx}.maximumInstallments`}
                    data-testid={`${parentRegisterName}.${idx}-maximum-installments-testid`}
                    labelText="Máximo de parcelas:"
                    optionalText=""
                    placeholder="0"
                    mask="xx"
                  />
                </Box>
                {fields.length > 1 && (
                  <Flex align="center" justify="right" css={{ height: '$12' }}>
                    <FloraButton
                      data-testid="service-center-filter-clear-btn"
                      hierarchy="tertiary"
                      has="iconOnly"
                      aria-label="Excluir condição"
                      icon={<CrossIcon size="32" />}
                      onClick={() => remove(idx)}
                    />
                  </Flex>
                )}
              </Grid>
            ))}
            <FloraButton
              as="button"
              size="small"
              hierarchy="tertiary"
              css={{ marginTop: '$4' }}
              type="button"
              onClick={() => {
                append({}, { shouldFocus: false });
              }}
              has="iconLeft"
              icon={<PlusIcon size="16" />}
              isFull={false}
            >
              Adicionar opção de parcelamento
            </FloraButton>
          </Box>
        </TogglableContent>
      </FormFieldset>

      <FormFieldset>
        <TogglableContent
          switchRegisterName={`${parentRegisterName}.hasMinimumInstallmentValue`}
          title="Valor mínimo de parcela"
        >
          <FormInputCurrency
            id={`${parentRegisterName}-minimum-value`}
            registerName={`${parentRegisterName}.minimumInstallmentValue`}
            data-testid={`${parentRegisterName}-minimum-value-testid`}
            labelText="Valor:"
          />
        </TogglableContent>
      </FormFieldset>
    </>
  );
};

export { InstallmentOptions };
