import { removeNonDigits, formatCnpj, formatCpf } from '@/utils';

const CPF_LENGTH = 11;

export const formatCpfOrCnpj = (cpfOrCnpj: string | undefined) => {
  if (!cpfOrCnpj) {
    return cpfOrCnpj;
  }
  const cleanCpfCnpj = removeNonDigits(cpfOrCnpj);

  return cleanCpfCnpj.length > CPF_LENGTH ? formatCnpj(cpfOrCnpj) : formatCpf(cpfOrCnpj);
};
