import { ServiceCenterStatusType, ServiceCenter } from '@/types';

import { fetcher } from '@/api/fetcher';

export async function getServiceCenterByCsCode(
  id: string | undefined,
  status?: ServiceCenterStatusType,
): Promise<ServiceCenter> {
  return fetcher.get<ServiceCenter>(
    `service-centers/info/${id}`,
    status
      ? {
          searchParams: { status },
        }
      : undefined,
  );
}
