import { removeNonDigits } from '@/utils';

const CPF_LENGTH = 11;

export const formatCpfCnpjInputMask = (value: string | undefined): string | undefined => {
  if (!value) {
    return undefined;
  }

  const cleanValue = removeNonDigits(value);
  return cleanValue.length > CPF_LENGTH ? 'xx.xxx.xxx/xxxx-xx' : 'xxx.xxx.xxx-xxx';
};
