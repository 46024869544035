import { Switch, SwitchProps } from '@grupoboticario/flora-react';
import { Controller, useFormContext } from 'react-hook-form';

interface ControlledSwitchProps extends Omit<SwitchProps, 'defaultValue'> {
  registerName: string;
  defaultValue?: boolean;
  onText?: string;
  offText?: string;
}

const ControlledSwitch = ({ registerName, defaultValue = false, ...rest }: ControlledSwitchProps): JSX.Element => {
  const { control } = useFormContext();

  return (
    <Controller
      name={registerName}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Switch
          {...rest}
          showTexts={false}
          onCheckedChange={(value: boolean) => {
            if (rest.onCheckedChange) {
              rest.onCheckedChange(value);
            }
            field.onChange(value);
          }}
          checked={field.value}
          {...field}
        />
      )}
    />
  );
};

export { ControlledSwitch };
