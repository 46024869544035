import { ControlledSelect, DropdownItemsProps, FormFooter, FormInput, FormWrapper } from '@/components';
import { RegistrationFormData, statesOfBrazilInfo } from '@/types';
import { Box, Toaster } from '@grupoboticario/flora-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import {
  formatRegistrationData,
  InputPhone,
  normalizeRegistrationData,
  registrationDataSchema,
  useZipCodeHandler,
} from '@/components/registration-form';
import { events, saveServiceCenter } from '@/api';
import { useServiceCenterForm } from '@/context';

const checkboxStatesOfBrazilValues: DropdownItemsProps[] = statesOfBrazilInfo.map(state => ({
  key: state.id,
  value: state.federativeUnit,
  name: state.name,
}));

const generateDefaultValues = (): RegistrationFormData => ({
  city: '',
  cpCode: '',
  csCode: '',
  district: '',
  name: '',
  number: '',
  state: '',
  street: '',
  zipCode: '',
  phones: [],
  additionalAddressDetails: '',
  email: '',
  isPrimaryPhone: '',
  operationalManager: '',
  referencePoint: '',
});

const handleDataSubmit = async (data: RegistrationFormData, id?: string) => {
  const formattedData = formatRegistrationData(data, id);
  return saveServiceCenter(formattedData);
};

const RegistrationForm = (): JSX.Element => {
  const { state, goToNextStep, setRegistrationData, isEditMode } = useServiceCenterForm();

  const defaultValues: RegistrationFormData = useMemo(
    () => state.formData.registrationData ?? generateDefaultValues(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const methods = useForm<RegistrationFormData>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(registrationDataSchema),
    mode: 'onBlur',
    defaultValues,
  });

  const {
    handleSubmit,
    trigger,
    register,
    formState: { errors, dirtyFields, isDirty, isSubmitting },
    getFieldState,
    control,
  } = methods;

  const shouldSkipRequestOnSubmit = useMemo(
    () => !isDirty && isEditMode && !!state.formData.registrationData,
    [isDirty, isEditMode, state.formData.registrationData],
  );

  const csCodeValue = useWatch({ name: 'csCode', control });
  const csCodeState = getFieldState('csCode');
  const canEditAddressFields = isEditMode || (!csCodeState.error && csCodeValue !== '');

  useEffect(() => {
    events.emit('UPDATE_CS_DATA', {
      isSubmitting,
    });
  }, [isSubmitting]);

  const onSubmit = useCallback(
    async (data: RegistrationFormData) => {
      try {
        if (shouldSkipRequestOnSubmit) {
          goToNextStep();
          return;
        }

        const response = await handleDataSubmit(data, state.formData.id);

        if (!response.franchise) {
          throw new Error('Formato de objeto não suportado, esperado Franchise');
        }

        setRegistrationData(
          // eslint-disable-next-line no-underscore-dangle
          response._id,
          normalizeRegistrationData(response.franchise),
        );

        events.emit('CLOSE_DRAWER_INFO');

        goToNextStep();
      } catch (e) {
        Toaster.notify({
          kind: 'error',
          description: 'Um erro ocorreu ao salvar esta etapa. Por favor, tente novamente mais tarde.',
          origin: 'right-top',
          duration: 60000,
          button: 'Fechar',
          closeWhenClick: true,
        });
      }
    },
    [goToNextStep, setRegistrationData, shouldSkipRequestOnSubmit, state.formData.id],
  );

  const { onZipCodeChange } = useZipCodeHandler(methods);

  return (
    <FormProvider {...methods}>
      <Box as="form" onSubmit={handleSubmit(onSubmit)} data-testid="registration-form">
        <FormWrapper title="Dados da Franquia">
          <Box css={{ gridColumn: 'span 4' }}>
            <FormInput
              registerName="cpCode"
              data-testid="input-codigo-cp"
              id="codigo-cs"
              labelText="Código do CP:"
              placeholder="54321"
              optionalText="*"
              readOnly={isEditMode}
            />
          </Box>
          <Box css={{ gridColumn: 'span 8' }}>
            <FormInput
              data-testid="name"
              registerName="name"
              id="nome"
              labelText="Nome do franqueado:"
              placeholder="Maria Eloiza Ferruda"
              optionalText="*"
            />
          </Box>
          <Box css={{ gridColumn: 'span 12' }}>
            <FormInput
              registerName="operationalManager"
              id="responsavel-operacional"
              labelText="Responsável operacional:"
              data-testid="input-responsavel-operacional"
            />
          </Box>
        </FormWrapper>
        <FormWrapper title="Dados da Central de Serviços">
          <Box css={{ gridColumn: 'span 6' }}>
            <FormInput
              registerName="csCode"
              data-testid="input-codigo-cs"
              id="codigo-cs"
              labelText="Código da CS:"
              placeholder="14122"
              optionalText="*"
              mask="xxxxxxxxxx"
              readOnly={isEditMode}
              onChange={() => trigger('csCode')}
            />
          </Box>
          <Box css={{ gridColumn: 'span 6' }}>
            <FormInput
              registerName="zipCode"
              data-testid="input-cep"
              id="cep"
              labelText="CEP:"
              placeholder="_____-___"
              mask="xxxxx-xxx"
              optionalText="*"
              disabled={!canEditAddressFields}
              onChange={onZipCodeChange}
            />
          </Box>
          <Box css={{ gridColumn: 'span 10' }}>
            <FormInput
              registerName="street"
              data-testid="input-logradouro"
              id="street"
              labelText="Endereço:"
              placeholder="Rua, Avenida, Alameda etc"
              optionalText="*"
              disabled={!canEditAddressFields}
            />
          </Box>
          <Box css={{ gridColumn: 'span 2' }}>
            <FormInput
              registerName="number"
              data-testid="input-numero"
              id="numero"
              labelText="Nº:"
              placeholder="_____"
              optionalText="*"
              disabled={!canEditAddressFields}
            />
          </Box>
          <Box css={{ gridColumn: 'span 6' }}>
            <FormInput
              registerName="additionalAddressDetails"
              data-testid="input-complemento"
              id="complemento:"
              labelText="Complemento:"
              disabled={!canEditAddressFields}
            />
          </Box>
          <Box css={{ gridColumn: 'span 6' }}>
            <FormInput
              registerName="district"
              data-testid="input-bairro"
              id="bairro:"
              labelText="Bairro:"
              placeholder="Ex. Centro"
              optionalText="*"
              disabled={!canEditAddressFields}
            />
          </Box>
          <Box css={{ gridColumn: 'span 6' }}>
            <ControlledSelect
              registerName="state"
              dropdownItems={checkboxStatesOfBrazilValues}
              id="state"
              labelText="Estado:"
              optionalText="Selecione"
              invalidText={errors?.state?.message}
              invalid={!!errors?.state}
              data-valid={dirtyFields.state && !errors.state}
            />
          </Box>
          <Box css={{ gridColumn: 'span 6' }}>
            <FormInput
              registerName="city"
              data-testid="input-cidade"
              id="cidade:"
              labelText="Cidade:"
              placeholder="Ex. São Paulo"
              optionalText="*"
              disabled={!canEditAddressFields}
            />
          </Box>
          <Box css={{ gridColumn: 'span 12' }}>
            <FormInput
              registerName="referencePoint"
              data-testid="input-ponto-referencia"
              id="ponto-referencia"
              labelText="Ponto de referência:"
              disabled={!canEditAddressFields}
            />
          </Box>
          <Box css={{ gridColumn: 'span 12' }}>
            <InputPhone isDisabled={!canEditAddressFields} />
          </Box>
          <Box css={{ gridColumn: 'span 12' }}>
            <FormInput
              registerName="email"
              data-testid="input-email"
              id="email"
              labelText="E-mail:"
              disabled={!canEditAddressFields}
              setValueAs={(value: string) => (value === '' ? undefined : value)}
            />
          </Box>
        </FormWrapper>
        <FormFooter hasBackButton={false} />

        <input hidden {...register('serviceCenterId')} defaultValue={state.formData.id} />
      </Box>
    </FormProvider>
  );
};

export { RegistrationForm };
