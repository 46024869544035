import { Flex, FloraTypography } from '@grupoboticario/flora-react';
import { FormCheckbox } from '../form-checkbox';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';

type CheckboxDataType = Pick<React.ComponentProps<typeof FormCheckbox>, 'id' | 'label' | 'value'>;
interface GroupCheckboxProps {
  checkboxes: CheckboxDataType[];
  disabled?: boolean;
  title?: string;
  registerName: string;
}

export const GroupCheckbox = ({ checkboxes, disabled, title, registerName }: GroupCheckboxProps) => {
  const { getFieldState, formState, unregister, getValues, setValue } = useFormContext();
  const fieldState = getFieldState(registerName, formState);

  useEffect(() => {
    const value = getValues(registerName);
    if (!(value instanceof Array)) {
      setValue(registerName, []);
    }
    return () => {
      unregister(registerName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex direction="column" gap="$4">
      <FloraTypography
        fontSize="exceptionsRestricted"
        css={{
          color: '$nonInteractiveAuxiliar',
        }}
      >
        {title}
      </FloraTypography>
      {checkboxes?.map(checkboxData => (
        <FormCheckbox
          registerName={registerName}
          key={checkboxData.label + checkboxData.id}
          {...checkboxData}
          disabled={disabled}
        />
      ))}
      {fieldState.invalid && (
        <FloraTypography fontSize="exceptionsAuxiliar" fontWeight="bold" color="$1">
          {fieldState.error?.message}
        </FloraTypography>
      )}
    </Flex>
  );
};
