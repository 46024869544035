import React, { ReactNode, useMemo, useCallback, useReducer, useRef } from 'react';
import { useTableActions } from '@/components/table/actions';
import { useTableEffects } from '@/components/table/hooks';
import { tableReducer, getTableInitialState } from '../reducer';
import { TableState, TableCustomFilter, TableSortType, TableColumn, TableLoadFnType } from '@/components/table/types';

interface TableValue {
  itemsPerPage?: number;
  hasPagination: boolean;
  tableState: TableState;
  rowKey: string;
  applyCustomFilter: (customFilter?: TableCustomFilter) => void;
  applySort: (sort?: TableSortType) => void;
  onPageChange: (page: number) => void;
}

interface TableProviderProps {
  columns: TableColumn[];
  children: ReactNode;
  itemsPerPage?: number;
  hasPagination?: boolean;
  rowKey?: string;
  loadData: TableLoadFnType;
}

const TableContext = React.createContext<TableValue | undefined>(undefined);

const TableProvider = ({ columns, children, itemsPerPage, hasPagination, rowKey, loadData }: TableProviderProps) => {
  const [tableState, dispatch] = useReducer(tableReducer, getTableInitialState());
  const dispatchRef = useRef(dispatch);
  const { dispatchLoadData } = useTableActions(dispatchRef, loadData);

  const sortString = useMemo(
    () => (tableState.sort ? `${tableState.sort.field}:${tableState.sort.order}` : undefined),
    [tableState.sort],
  );

  const onPageChange = useCallback(
    (page: number) => dispatchRef.current({ type: 'SET_TABLE_PAGE', payload: page }),
    [],
  );

  const applySort = useCallback(
    (sort?: TableSortType) => dispatchRef.current({ type: 'SET_TABLE_SORT', payload: sort }),
    [],
  );

  const applyCustomFilter = useCallback(
    (customFilter?: TableCustomFilter) =>
      dispatchRef.current({
        type: 'SET_TABLE_CUSTOM_FILTER',
        payload: customFilter,
      }),
    [],
  );

  useTableEffects(dispatch, tableState, columns, dispatchLoadData, sortString, itemsPerPage);

  const contextValue = useMemo(
    () => ({
      tableState,
      itemsPerPage,
      hasPagination: !!hasPagination,
      rowKey: rowKey ?? 'id',
      applyCustomFilter,
      applySort,
      onPageChange,
    }),
    [tableState, itemsPerPage, hasPagination, rowKey, applyCustomFilter, applySort, onPageChange],
  );

  return <TableContext.Provider value={contextValue}>{children}</TableContext.Provider>;
};

const useTable = (): TableValue => {
  const context = React.useContext(TableContext);

  if (context === undefined) {
    throw new Error('useTable deve ser utilizado dentro de um TableContext');
  }

  return context;
};

export { TableContext, TableProvider, useTable };
