import { BusinessHoursFormData, DayFormData, checkboxDaysValues } from '@/types';
import { Flex } from '@grupoboticario/flora-react';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { CheckboxDay, OpenHoursList } from '@/components/business-hours-form';
import { FormCheckbox } from '@/components/shared';

interface BusinessScheduleCalendarProps {
  index: number;
}

const BusinessScheduleCalendar = ({ index }: BusinessScheduleCalendarProps): JSX.Element => {
  const { resetField } = useFormContext<BusinessHoursFormData>();

  const selectedCheckboxDays = useWatch({
    name: `days.${index}.day`,
  });

  const selectedDays = useWatch({
    name: `days`,
  });

  const isClosed = useWatch({
    name: `days.${index}.closed`,
  });

  const mapSelectedDays = selectedDays
    .map((selectedDay: DayFormData, idx: number) => {
      return idx !== index && selectedDay.day;
    })
    .flat();

  useEffect(() => {
    if (isClosed) {
      resetField(`days.${index}.hours`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClosed]);

  return (
    <Flex
      direction="column"
      css={{
        marginTop: '$8',
        paddingTop: '$8',
        borderTop: '1px solid $nonInteractiveAltAuxiliar',
        '&:first-child': {
          borderTop: '0',
          paddingTop: '0',
        },
      }}
    >
      <Flex align="center" justify="space-between">
        {checkboxDaysValues.map(checkbox => (
          <CheckboxDay
            key={checkbox.value}
            label={checkbox.label}
            value={checkbox.value}
            inputIndex={index}
            isChecked={selectedCheckboxDays.includes(checkbox.value)}
            disabled={mapSelectedDays.includes(checkbox.value)}
          />
        ))}
      </Flex>
      <Flex align="center" gap="$2" css={{ padding: '$8 0' }}>
        <FormCheckbox
          label="Fechado"
          registerName={`days.${index}.closed`}
          id={`days.${index}`}
          data-testid={`formCheckboxDays.${index}`}
        />
      </Flex>
      {!isClosed && <OpenHoursList parentRegisterName="days" index={index} />}
    </Flex>
  );
};

export { BusinessScheduleCalendar };
