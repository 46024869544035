import * as yup from 'yup';
import { BankSlipPaymentFormData } from '@/types';
import { paymentFormDataSchema } from './paymentFormDataSchema';
import { acceptsInstallmentsFormDataSchema } from './acceptsInstallmentsFormDataSchema';

export const bankSlipPaymentFormDataSchema = yup.object<BankSlipPaymentFormData>().shape({
  hasPaymentCash: yup.boolean().optional(),
  hasThirtyDaysPayment: yup.boolean().optional(),
  consumesAvailableCredit: yup.boolean().optional(),
  ...paymentFormDataSchema.fields,
  ...acceptsInstallmentsFormDataSchema.fields,
});
