import { DepositAndTransferPaymentFormData, AccountType } from '@/types';
import { cpfCnpjValueTest } from '@/utils';
import * as yup from 'yup';
import { paymentFormDataSchema } from './paymentFormDataSchema';
import { requestPaymentVoucherFormDataSchema } from './requestPaymentVoucherFormDataSchema';

const REQUIRED_ERROR_MESSAGE = 'Esse campo é obrigatório.';

const generateNumberFieldSchema = () =>
  yup.string().when('isEnabled', {
    is: true,
    then: schema =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      schema.integer().required(REQUIRED_ERROR_MESSAGE),
    otherwise: schema => schema.optional(),
  });

export const depositAndTransferPaymentFormDataSchema = yup.object<DepositAndTransferPaymentFormData>().shape({
  ...paymentFormDataSchema.fields,
  ...requestPaymentVoucherFormDataSchema.fields,
  accountNumber: generateNumberFieldSchema(),
  bank: generateNumberFieldSchema(),
  bankBranch: generateNumberFieldSchema(),
  cpfCnpj: yup.string().when('isEnabled', {
    is: true,
    then: schema => schema.required(REQUIRED_ERROR_MESSAGE).test(cpfCnpjValueTest),
    otherwise: schema => schema.optional(),
  }),
  accountType: yup
    .mixed<AccountType>()
    .oneOf(Object.values(AccountType))
    .when('isEnabled', {
      is: true,
      then: schema => schema.required(REQUIRED_ERROR_MESSAGE),
      otherwise: schema => schema.optional(),
    }),
});
