import { Dispatch, useCallback } from 'react';
import {
  RegistrationFormData,
  BusinessHoursFormData,
  OrderIncrementFormData,
  StarterPackFormData,
  PickUpStoreFormData,
  DeliveryFormData,
  LockAndLimitFormData,
  PaymentMethodsFormData,
  DocumentationFormData,
} from '@/types';
import { ServiceCenterFormActionType } from '@/actions';

export const useServiceCenterFormActions = (dispatch: Dispatch<ServiceCenterFormActionType>) => {
  const goToStep = useCallback(
    (stepNumber: number) => {
      dispatch({ type: 'SET_WIZARD_STEP', payload: stepNumber });
    },
    [dispatch],
  );

  const goToNextStep = useCallback(() => {
    dispatch({ type: 'INCREMENT_WIZARD_STEP' });
  }, [dispatch]);

  const goToPreviousStep = useCallback(() => {
    dispatch({ type: 'DECREMENT_WIZARD_STEP' });
  }, [dispatch]);

  const setRegistrationData = useCallback(
    (id: string, payload: RegistrationFormData | undefined) => {
      dispatch({ type: 'SET_REGISTRATION_DATA', payload });
      dispatch({ type: 'SET_SERVICE_CENTER_ID', payload: id });
    },
    [dispatch],
  );

  const setBusinessHours = useCallback(
    (payload: BusinessHoursFormData | undefined) => {
      dispatch({ type: 'SET_BUSINESS_HOURS', payload });
    },
    [dispatch],
  );

  const setOrderIncrement = useCallback(
    (payload: OrderIncrementFormData | undefined) => {
      dispatch({ type: 'SET_ORDER_INCREMENT', payload });
    },
    [dispatch],
  );

  const setStarterPack = useCallback(
    (payload: StarterPackFormData | undefined) => {
      dispatch({ type: 'SET_STARTER_PACK', payload });
    },
    [dispatch],
  );

  const setPickUpStore = useCallback(
    (payload: PickUpStoreFormData | undefined) => {
      dispatch({ type: 'SET_PICK_UP_STORE', payload });
    },
    [dispatch],
  );

  const setDelivery = useCallback(
    (payload: DeliveryFormData | undefined) => {
      dispatch({ type: 'SET_DELIVERY', payload });
    },
    [dispatch],
  );

  const setLockAndLimit = useCallback(
    (payload: LockAndLimitFormData | undefined) => {
      dispatch({ type: 'SET_LOCK_AND_LIMIT', payload });
    },
    [dispatch],
  );

  const setPaymentMethods = useCallback(
    (payload: PaymentMethodsFormData | undefined) => {
      dispatch({ type: 'SET_PAYMENT_METHODS', payload });
    },
    [dispatch],
  );

  const setDocumentationData = useCallback(
    (payload: DocumentationFormData | undefined) => {
      dispatch({ type: 'SET_DOCUMENTATION', payload });
    },
    [dispatch],
  );

  return {
    goToStep,
    goToNextStep,
    goToPreviousStep,
    setRegistrationData,
    setBusinessHours,
    setOrderIncrement,
    setStarterPack,
    setPickUpStore,
    setDelivery,
    setLockAndLimit,
    setPaymentMethods,
    setDocumentationData,
  };
};
