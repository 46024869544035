import { Dispatch, useEffect, useMemo } from 'react';
import { TableActionType } from '@/components/table/actions';
import { TableState, TableColumn, TableLoadFnParams } from '@/components/table/types';

export const useTableEffects = (
  dispatch: Dispatch<TableActionType>,
  tableState: TableState,
  columns: TableColumn[],
  dispatchLoadData: (params: TableLoadFnParams) => Promise<void>,
  sortString: string | undefined,
  itemsPerPage?: number,
) => {
  const requestPage = useMemo(() => tableState.currentPage + 1, [tableState.currentPage]);

  useEffect(() => {
    if (tableState.isInitialized) {
      dispatchLoadData({
        itemsPerPage,
        page: requestPage,
        sort: sortString,
        customFilter: tableState.customFilter,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortString, requestPage, tableState.customFilter, dispatchLoadData]);

  useEffect(() => {
    dispatch({ type: 'SET_TABLE_COLUMNS', payload: columns });
    dispatch({ type: 'INIT_TABLE' });
    dispatchLoadData({ page: requestPage, itemsPerPage, sort: undefined });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
