import { DayOfTheWeek } from './serviceEnum';

export const checkboxDaysValues = [
  {
    label: 'S',
    value: DayOfTheWeek.MONDAY,
  },
  {
    label: 'T',
    value: DayOfTheWeek.THURSDAY,
  },
  {
    label: 'Q',
    value: DayOfTheWeek.WEDNESDAY,
  },
  {
    label: 'Q',
    value: DayOfTheWeek.TUESDAY,
  },
  {
    label: 'S',
    value: DayOfTheWeek.FRIDAY,
  },
  {
    label: 'S',
    value: DayOfTheWeek.SATURDAY,
  },
  {
    label: 'D',
    value: DayOfTheWeek.SUNDAY,
  },
];
