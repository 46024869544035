import { useCallback, useMemo } from 'react';
import { Box, Input } from '@grupoboticario/flora-react';
import { SearchIconButton, ClearSearchIconButton } from '@/components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useTable } from '@/components/table/context';

interface ServiceCenterSearchFormValues {
  filter: string;
}

const MIN_LENGTH_ERROR = 'A busca deve conter pelo menos dois caracteres.';
const INVALID_VALUE_ERROR = 'Por favor, informe um franqueado ou código CS válido.';

const ServiceCenterSearch = (): JSX.Element => {
  const { applyCustomFilter, tableState } = useTable();

  const formSchema = useMemo(
    () =>
      yup.object({
        filter: yup
          .string()
          .min(2, MIN_LENGTH_ERROR)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .or(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            [yup.string().lettersAndSpaces(), yup.string().integer()],
            INVALID_VALUE_ERROR,
          ),
      }),
    [],
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ServiceCenterSearchFormValues>({
    defaultValues: { filter: '' },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(formSchema),
  });

  const onSubmit = useCallback(
    (data: ServiceCenterSearchFormValues) => {
      const isCode = /^\d+$/.test(data.filter ?? '');
      const fieldFilter = { field: isCode ? 'csCode' : 'name' };
      applyCustomFilter(data.filter ? { ...data, ...fieldFilter } : undefined);
    },
    [applyCustomFilter],
  );

  const onReset = useCallback(() => {
    applyCustomFilter();
    setTimeout(() => {
      reset({ filter: '' });
    }, 300);
  }, [reset, applyCustomFilter]);

  const isFilterActive = useMemo(() => tableState.customFilter?.filter, [tableState.customFilter]);

  const hasError = !!errors.filter;

  return (
    <Box
      as="form"
      data-testid="search-service-center-filter-form"
      onSubmit={handleSubmit(onSubmit)}
      css={{
        marginBottom: hasError ? '$8' : '$4',
      }}
    >
      <Input
        data-testid="search-service-center-filter-input"
        id="search-service-center-filter"
        labelText="Buscar:"
        optionalText=""
        defaultValue=""
        invalidText={errors?.filter?.message}
        invalid={!!errors?.filter}
        rightIcon={isFilterActive ? <ClearSearchIconButton onClick={onReset} /> : <SearchIconButton />}
        {...register('filter')}
      />
    </Box>
  );
};

export { ServiceCenterSearch };
