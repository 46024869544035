import { Box, CSS } from '@grupoboticario/flora-react';
import { useTable } from '@/components/table/context';
import { TableCell, TableRow } from '@/components/table/components';

interface TableBodyProps {
  css?: CSS;
}

const TableBody = ({ css }: TableBodyProps): JSX.Element => {
  const { rowKey, tableState } = useTable();
  const { data, columns } = tableState;

  return (
    <Box as="tbody" data-testid="table-body" css={css}>
      {data.map((entry, idx) => (
        <TableRow key={`table-body-row__${entry[rowKey]}`} isEvenRow={(idx + 1) % 2 === 0}>
          {columns.map(column => {
            const key = `table-body-row-cell__${entry[rowKey]}-${column.key}`;
            if (column.customDataCellRenderer) {
              return <TableCell key={key}>{column.customDataCellRenderer(entry[column.key], entry)}</TableCell>;
            }
            return <TableCell key={key}>{entry[column.key]}</TableCell>;
          })}
        </TableRow>
      ))}
    </Box>
  );
};

export { TableBody };
