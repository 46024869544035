import { useMemo } from 'react';
import { Flex, Tooltip } from '@grupoboticario/flora-react';
import { ArrowUpDownIcon, ChevronDownIcon, ChevronUpIcon } from '@grupoboticario/flora-react-icons';
import { useTable } from '@/components/table/context';
import { TableSortType } from '@/components/table/types';

interface TableSortProps {
  field: string;
}

const TableSort = ({ field }: TableSortProps): JSX.Element => {
  const { applySort } = useTable();
  const { sort } = useTable().tableState;

  const sortToApply = useMemo((): TableSortType | undefined => {
    if (sort === undefined || sort.field !== field) {
      return {
        field,
        order: 'asc',
      };
    }

    if (sort.order === 'asc') {
      return {
        field,
        order: 'desc',
      };
    }

    return undefined;
  }, [sort, field]);

  return (
    <Tooltip direction="bottom" text="Ordenar">
      <Flex
        data-testid={`table-sort-${field}`}
        align="center"
        justify="center"
        css={{
          height: '$4',
          width: '$4',
          marginLeft: '$2',
          color: '$backgroundAltPrimary',
          cursor: 'pointer',
        }}
        onClick={() => applySort(sortToApply)}
      >
        {(sort === undefined || sort.field !== field) && <ArrowUpDownIcon size="16" />}
        {sort?.order === 'asc' && sort.field === field && <ChevronDownIcon size="16" />}
        {sort?.order === 'desc' && sort.field === field && <ChevronUpIcon size="16" />}
      </Flex>
    </Tooltip>
  );
};

export { TableSort };
