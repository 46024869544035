import { FloraTypography } from '@grupoboticario/flora-react';
import { Divider } from '@/components';

interface PaymentHeaderProps {
  hasTitle?: boolean;
}

const PaymentHeader = ({ hasTitle = true }: PaymentHeaderProps) => (
  <>
    <Divider css={{ marginTop: '$3', marginBottom: '$6' }} />
    {hasTitle && (
      <FloraTypography
        fontSize="exceptionsRestricted"
        css={{
          color: '$nonInteractiveAuxiliar',
          gridColumn: 'span 12',
          marginBottom: '$4',
          fontWeight: '$medium',
        }}
      >
        Condições gerais
      </FloraTypography>
    )}
  </>
);

export { PaymentHeader };
