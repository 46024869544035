import { Flex } from '@grupoboticario/flora-react';
import { FormFieldset, InputNote } from '@/components/';
import { InstallmentOptions, PaymentHeader, PaymentLocations } from '@/components/payment-methods-form/';

export const CreditCardPayment = () => (
  <Flex direction="column">
    <PaymentHeader />
    <PaymentLocations parentRegisterName="creditCardPayment" />
    <InstallmentOptions parentRegisterName="creditCardPayment" acceptInstallmentsLabel="Aceita parcelamento?" />
    <FormFieldset>
      <InputNote registerName="creditCardPayment.note" />
    </FormFieldset>
  </Flex>
);
