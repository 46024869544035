import { Flex, FloraTypography } from '@grupoboticario/flora-react';

import { useWatch } from 'react-hook-form';
import { FormFieldset, TogglableContent, FormCheckbox, FormInput } from '@/components/';
import {
  RequestPaymentVoucher,
  SendPaymentVoucher,
  sendPaymentVouchersValueLabelMap,
  PaymentVoucherSpecification,
  paymentVoucherSpecificationValueLabelMap,
  PaymentMethodsFormData,
} from '@/types';

interface CheckboxData<T> {
  label: string;
  id: string;
  value: T;
}

interface CheckboxSection {
  title: string;
  registerName: keyof Pick<RequestPaymentVoucher, 'sendTo' | 'specifications'>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  checkboxes: CheckboxData<any>[];
}

const sendPaymentToCheckboxes: CheckboxData<SendPaymentVoucher>[] = [
  {
    label: sendPaymentVouchersValueLabelMap[SendPaymentVoucher.OPERATIONAL_MANAGER],
    id: 'send-to-operational-manager',
    value: SendPaymentVoucher.OPERATIONAL_MANAGER,
  },
  {
    label: sendPaymentVouchersValueLabelMap[SendPaymentVoucher.EMAIL],
    id: 'send-to-email',
    value: SendPaymentVoucher.EMAIL,
  },
  {
    label: sendPaymentVouchersValueLabelMap[SendPaymentVoucher.WHATSAPP],
    id: 'send-to-whatsapp',
    value: SendPaymentVoucher.WHATSAPP,
  },
];

const voucherSpecificationCheckboxes: CheckboxData<PaymentVoucherSpecification>[] = [
  {
    label: paymentVoucherSpecificationValueLabelMap[PaymentVoucherSpecification.INCLUDE_ORDER_INFO],
    id: 'include-order-info',
    value: PaymentVoucherSpecification.INCLUDE_ORDER_INFO,
  },
  {
    label: paymentVoucherSpecificationValueLabelMap[PaymentVoucherSpecification.INCLUDE_RESELLER_INFO],
    id: 'include-reseller-info',
    value: PaymentVoucherSpecification.INCLUDE_RESELLER_INFO,
  },
];

const sections: CheckboxSection[] = [
  {
    title: 'Opções de envio',
    checkboxes: sendPaymentToCheckboxes,
    registerName: 'sendTo',
  },
  {
    title: 'Especificações',
    checkboxes: voucherSpecificationCheckboxes,
    registerName: 'specifications',
  },
];

interface PaymentVoucherProps {
  parentRegisterName: keyof Pick<PaymentMethodsFormData, 'pixPayment' | 'depositAndTransferPayment'>;
}

const PaymentVoucher = ({ parentRegisterName }: PaymentVoucherProps): JSX.Element => {
  const sendToValues: string[] = useWatch({ name: `${parentRegisterName}.sendTo` }) ?? [];

  const shouldRenderInput = (fieldValue: string, expectedValue: string) =>
    fieldValue === expectedValue && sendToValues.includes(expectedValue);

  return (
    <FormFieldset>
      <TogglableContent
        switchRegisterName={`${parentRegisterName}.requestsPaymentVoucher`}
        title="Necessário solicitar o envio do comprovante?"
      >
        <Flex direction="column" gap="$8">
          {sections.map(section => (
            <Flex direction="column" key={`${parentRegisterName}.${section.title}`}>
              <FloraTypography
                fontSize="exceptionsRestricted"
                css={{
                  color: '$nonInteractiveAuxiliar',
                  gridColumn: 'span 12',
                  marginBottom: '$4',
                  fontWeight: '$medium',
                }}
              >
                {section.title}
              </FloraTypography>
              <Flex direction="column" gap="$4">
                {section.checkboxes.map(cb => (
                  <>
                    <FormCheckbox
                      key={cb.id}
                      label={cb.label}
                      registerName={`${parentRegisterName}.${section.registerName}`}
                      id={`${parentRegisterName}-${cb.id}`}
                      data-testid={`${parentRegisterName}-${cb.id}`}
                      value={cb.value}
                    />
                    {shouldRenderInput(cb.value, 'email') && (
                      <FormInput
                        id={`${parentRegisterName}-${cb.id}-email`}
                        labelText="E-mail:"
                        optionalText="exemplo@email.com.br"
                        registerName={`${parentRegisterName}.paymentVoucherEmail`}
                      />
                    )}
                    {shouldRenderInput(cb.value, 'whatsapp') && sendToValues.includes('whatsapp') && (
                      <FormInput
                        id={`${parentRegisterName}-${cb.id}-whatsapp`}
                        labelText="Telefone:"
                        optionalText="(00) 00000-0000"
                        mask="(xx) xxxxx-xxxx"
                        registerName={`${parentRegisterName}.paymentVoucherWhatsapp`}
                      />
                    )}
                  </>
                ))}
              </Flex>
            </Flex>
          ))}
        </Flex>
      </TogglableContent>
    </FormFieldset>
  );
};

export { PaymentVoucher };
