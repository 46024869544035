import { fetcher } from '@/api/fetcher';
import { GetServiceCentersResponse } from '@/types';

export interface GetServiceCentersRequest {
  page?: number;
  limit?: number;
  sort?: string;
  field?: string;
  filter?: string;
}

export async function getServiceCenters(queryParams: GetServiceCentersRequest): Promise<GetServiceCentersResponse> {
  return fetcher.get<GetServiceCentersResponse>('service-centers', {
    searchParams: { ...queryParams },
  });
}
