import { BusinessHoursFormData } from '@/types';
import { Flex, CSS } from '@grupoboticario/flora-react';
import { useFormContext } from 'react-hook-form';

interface CheckboxDayProps {
  label: string;
  value: string;
  inputIndex: number;
  disabled?: boolean;
  isChecked: boolean;
}
const handleCheckboxDayColors = (isChecked: boolean, disabled: boolean): CSS => {
  if (isChecked && !disabled) {
    return {
      backgroundColor: '$linkDefault',
      borderColor: '$backgroundTertiary',
      color: '$backgroundPrimary',
      cursor: 'pointer',
    };
  }

  if (disabled) {
    return {
      backgroundColor: '$nonInteractiveAltAuxiliar',
      color: '$backgroundPrimary',
      borderColor: '$nonInteractiveAltAuxiliar',
      cursor: 'default',
    };
  }

  return {
    backgroundColor: '$backgroundPrimary',
    borderColor: '$linkDefault',
    color: '$linkDefault',
    cursor: 'pointer',
  };
};

const CheckboxDay = ({ label, value, inputIndex, isChecked, disabled = false }: CheckboxDayProps): JSX.Element => {
  const { register } = useFormContext<BusinessHoursFormData>();
  const { backgroundColor, borderColor, color, cursor } = handleCheckboxDayColors(isChecked, disabled);
  const checkboxId = `${value}-${inputIndex}`;
  return (
    <>
      <Flex
        data-testid={isChecked ? 'checkbox-checked' : 'checkbox-day'}
        as="label"
        justify="center"
        align="center"
        htmlFor={checkboxId}
        css={{
          backgroundColor,
          borderRadius: '$pill',
          color,
          cursor,
          height: '$13',
          border: '1px solid',
          borderColor,
          width: '$13',
        }}
      >
        {label}
      </Flex>
      <input
        {...register(`days.${inputIndex}.day` as const)}
        data-testid="input-checkbox-day"
        name={`days.${inputIndex}.day`}
        type="checkbox"
        id={checkboxId}
        value={value}
        disabled={disabled}
        hidden
      />
    </>
  );
};

export { CheckboxDay };
