import { TableColumn, TableLoadFnType, TableProvider, TableValueType, TableWrapper } from '@/components/table';
import { PhoneTooltipCell, TableActions, ServiceCenterSearch } from '@/components';
import { getServiceCenters } from '@/api';
import { format, parseISO } from 'date-fns';
import { Box, Grid } from '@grupoboticario/flora-react';
import { capitalizeText } from '@/utils';

const columns: TableColumn[] = [
  {
    key: 'name',
    name: 'Franqueado',
    isSortable: true,
    customDataCellRenderer: (name: string) => capitalizeText(name),
  },
  {
    key: 'csCode',
    name: 'Código CS',
    isSortable: true,
  },
  {
    key: 'phones',
    name: 'Contato',
    customDataCellRenderer: (value: TableValueType) => <PhoneTooltipCell phones={value} />,
  },
  {
    key: 'region',
    name: 'Região ',
    isSortable: true,
  },
  {
    key: 'updatedAt',
    name: 'Última Atualização',
    isSortable: true,
    customDataCellRenderer: (value: TableValueType) => {
      return format(parseISO(value), 'dd/MM/yyyy HH:mm');
    },
  },
  {
    key: 'actions',
    name: 'Ações',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customDataCellRenderer: (_, entry: any) => <TableActions id={entry.csCode} />,
  },
];

const fieldToRequestMap = {
  name: 'franchise.name',
  csCode: 'franchise.address.csCode',
};

const getServiceCenterData: TableLoadFnType = async params => {
  const customFilter: { field?: string; filter?: string } = {};

  if (params.customFilter?.field) {
    customFilter.field = fieldToRequestMap[params.customFilter.field as 'name' | 'csCode'];
    customFilter.filter = params.customFilter?.filter as string;
  }

  const queryParams = {
    page: params.page,
    limit: params.itemsPerPage,
    sort: params.sort,
    ...customFilter,
  };

  if (!queryParams.sort) {
    delete queryParams.sort;
  }

  const { results, data } = await getServiceCenters(queryParams);

  return {
    totalResults: results,
    data: data,
  };
};

const ServiceCenterTable = () => {
  return (
    <TableProvider columns={columns} hasPagination itemsPerPage={10} rowKey="_id" loadData={getServiceCenterData}>
      <Grid css={{ width: '100%' }} templateColumns="repeat(2, 1fr)">
        <ServiceCenterSearch />
        <Box css={{ gridColumn: 'span 2' }}>
          <TableWrapper />
        </Box>
      </Grid>
    </TableProvider>
  );
};

export { ServiceCenterTable };
