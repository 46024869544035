import { FederativeUnit, PhoneDataForm, RegistrationFormData, SaveRegistrationDataRequestType } from '@/types';
import { removeNonDigits } from '@/utils';

const formatPhoneData = (data: RegistrationFormData) => {
  return data.phones.map((phone: PhoneDataForm) => ({
    phone: removeNonDigits(phone.number),
    primary: data.isPrimaryPhone === phone.key || data.phones.length === 1,
    whatsapp: !!phone.hasWhatsApp,
  }));
};

export const formatRegistrationData = (data: RegistrationFormData, id?: string): SaveRegistrationDataRequestType => {
  const formattedPhoneData = formatPhoneData(data);

  const {
    cpCode,
    csCode,
    city,
    district: landmark,
    name,
    number,
    street,
    zipCode,
    state,
    email,
    additionalAddressDetails,
    referencePoint,
    operationalManager: owner,
  } = data;

  const formattedData: SaveRegistrationDataRequestType = {
    step: '1',
    _id: id,
    franchise: {
      contacts: { phones: formattedPhoneData, email },
      cpCode,
      address: {
        city,
        csCode,
        referencePoint,
        number,
        landmark,
        state: state.toUpperCase() as FederativeUnit,
        street,
        zipCode: removeNonDigits(zipCode),
        complement: additionalAddressDetails,
      },
      name,
      owner,
    },
  };

  return formattedData;
};
