import { useCallback, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { RegistrationFormData } from '@/types';
import { removeNonDigits } from '@/utils';
import { useGetPostalService } from '@/api';

type AddressFieldsType = 'street' | 'district' | 'city' | 'state';

export const useZipCodeHandler = (formMethods: UseFormReturn<RegistrationFormData>) => {
  const [cep, setCep] = useState(formMethods.getValues('zipCode'));

  const { data: address, isLoading } = useGetPostalService(cep);
  const { setValue } = formMethods;

  const updateValue = (key: AddressFieldsType, value: string | undefined) => {
    if (value) {
      setValue(key, value);
    }
  };

  useEffect(() => {
    if (address && !isLoading) {
      updateValue('street', address.streetAddress);
      updateValue('district', address.district);
      updateValue('city', address.addressLocality);
      updateValue('state', address.addressRegion);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cep, isLoading]);

  const onZipCodeChange = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
    const updatedCep = removeNonDigits(evt.target.value) ?? '';
    if (updatedCep.length === 8) {
      setCep(updatedCep);
    }
  }, []);

  return {
    onZipCodeChange,
  };
};
