import { BusinessHours, BusinessHoursFormData } from '@/types';

export const normalizeBusinessHoursFormData = (
  businessHours: BusinessHours | undefined,
): BusinessHoursFormData | undefined => {
  return businessHours
    ? ({
        holidays: businessHours.holidays,
        note: businessHours.note,
        days: businessHours.days,
      } as BusinessHoursFormData)
    : undefined;
};
