import { useParams } from 'react-router-dom';
import { Loading, ServiceCenterForm, ServiceCenterFormWrapper } from '@/components';
import { serviceCenterToServiceCenterFormState } from '@/components/edit-service-center/utils';
import { useEffect, useState } from 'react';
import { getServiceCenterByCsCode } from '@/api';
import { ServiceCenter } from '@/types';
import { Flex, Toaster } from '@grupoboticario/flora-react';

const EditServiceCenter = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean | undefined>(false);
  const [data, setData] = useState<ServiceCenter>();

  useEffect(() => {
    const fetchData = async () => {
      if (!id) {
        return;
      }
      try {
        setIsLoading(true);
        const response = await getServiceCenterByCsCode(id);
        setData(response);
      } catch (error) {
        Toaster.notify({
          kind: 'error',
          duration: 60000,
          button: 'Fechar',
          closeWhenClick: true,
          description: 'Um erro ocorreu ao carregar os dados. Tente novamente.',
          origin: 'right-bottom',
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
    return () => {
      setData(undefined);
      setIsLoading(undefined);
    };
  }, [id]);

  if (isLoading) {
    return (
      <Flex
        data-testid="service-center-loading"
        direction="column"
        align="center"
        justify="center"
        css={{ marginTop: '$20' }}
      >
        <Loading size="lg" thickness="4px" />
      </Flex>
    );
  }
  return (
    <ServiceCenterFormWrapper title="Editar Central de Serviço">
      {data && <ServiceCenterForm initialState={serviceCenterToServiceCenterFormState(data)} />}
    </ServiceCenterFormWrapper>
  );
};

export { EditServiceCenter };
