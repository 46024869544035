import { Box, CSS, Flex } from '@grupoboticario/flora-react';
import { useTable } from '../../context';
import {
  LoadingTableData,
  TableBody,
  TableEmptySate,
  TableHeader,
  TablePagination,
} from '@/components/table/components';

interface TableProps {
  css?: CSS;
}

const TableWrapper = ({ css }: TableProps): JSX.Element => {
  const { isLoading, data } = useTable().tableState;

  const displayDataTable = !!data.length && !isLoading;
  const displayEmptyState = !data.length && !isLoading;

  return (
    <Flex direction="column" css={{ width: '100%' }}>
      <Box as="table" css={{ width: '100%', ...css }}>
        <TableHeader />
        {displayDataTable && <TableBody />}
      </Box>

      {isLoading && <LoadingTableData />}
      {displayEmptyState && <TableEmptySate />}

      <Flex justify="center">
        <TablePagination />
      </Flex>
    </Flex>
  );
};

export { TableWrapper };
