import { Flex, FloraRadio } from '@grupoboticario/flora-react';
import { Controller, useFormContext } from 'react-hook-form';

interface ConfigGapSize {
  row?: string;
  column?: string;
}
export interface ControlledRadioButtonData {
  id: string;
  label: string;
  value: string | number;
}

interface ControlledRadioGroupProps {
  registerName: string;
  defaultValue?: string;
  direction?: 'column' | 'row';
  disabled?: boolean;
  options: ControlledRadioButtonData[];
  gapSize?: ConfigGapSize;
  required?: boolean;
}

const defaultGapSize = {
  row: '$4',
  column: '$2',
};

const ControlledRadioGroup = ({
  registerName,
  defaultValue,
  direction = 'column',
  disabled = false,
  gapSize = defaultGapSize,
  options = [],
  required = false,
}: ControlledRadioGroupProps): JSX.Element => {
  const { control } = useFormContext();

  return (
    <Controller
      name={registerName}
      defaultValue={defaultValue}
      control={control}
      rules={{ required }}
      render={({ field, fieldState }) => (
        <Flex direction={direction} gap={gapSize[direction]}>
          {options.map(option => (
            <FloraRadio
              checked={field.value === option.value}
              onChange={field.onChange}
              key={option.id}
              id={option.id}
              label={option.label}
              name={registerName}
              value={option.value}
              disabled={disabled}
              css={{
                color: fieldState.error ? '$error-alt' : 'unset',
              }}
            />
          ))}
        </Flex>
      )}
    />
  );
};

export { ControlledRadioGroup };
