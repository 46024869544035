import React from 'react';
import { Divider } from '@/components';
import { Box } from '@grupoboticario/flora-react';

interface FormFieldsetProps {
  hasDivider?: boolean;
}
const FormFieldset = ({ children, hasDivider = true }: React.PropsWithChildren<FormFieldsetProps>): JSX.Element => (
  <>
    {hasDivider && <Divider css={{ my: '$8', gridColumn: 'span 12' }} />}
    <Box css={{ gridColumn: 'span 12' }}>{children}</Box>
  </>
);

export { FormFieldset };
