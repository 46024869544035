import { FormProvider, useForm } from 'react-hook-form';
import { FormFieldset, FormFooter, FormInput, FormWrapper, TogglableContent } from '../shared';
import { Box, Flex, FloraTypography, Toaster } from '@grupoboticario/flora-react';
import { ExclamationMarkTriangleOutlineIcon } from '@grupoboticario/flora-react-icons';
import { useEffect, useMemo } from 'react';
import { useServiceCenterForm } from '@/context';
import { DocumentationFormData } from '@/types';
import { events, saveServiceCenter } from '@/api';
import { formatDocumentationFormData } from '@/components/documentation-form/utils';
import { documentationFormDataSchema } from './validators';
import { yupResolver } from '@hookform/resolvers/yup';

const generateDefaultValues = (): DocumentationFormData => ({
  hasAdditionalDocumentation: false,
});

export const DocumentationForm = () => {
  const { goToNextStep, state, setDocumentationData, isEditMode } = useServiceCenterForm();

  const defaultValues = useMemo(
    () => state.formData.documentation ?? generateDefaultValues(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const methods = useForm<DocumentationFormData>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(documentationFormDataSchema),
    mode: 'onBlur',
    defaultValues,
  });

  const {
    formState: { isDirty, isSubmitting },
  } = methods;

  useEffect(() => {
    events.emit('UPDATE_CS_DATA', {
      isSubmitting,
    });
  }, [isSubmitting]);

  const shouldSkipRequestOnSubmit = useMemo(
    () => !isDirty && isEditMode && !!state.formData.documentation,
    [isDirty, isEditMode, state.formData.documentation],
  );

  const handleDataSubmit = async (data: DocumentationFormData, csId?: string) => {
    if (csId === undefined) {
      throw new Error('Service Center ID não pode ser nulo');
    }

    const formattedData = formatDocumentationFormData(data, csId);
    return saveServiceCenter(formattedData);
  };
  const onSubmitDocumentationFormData = async (data: DocumentationFormData) => {
    try {
      if (shouldSkipRequestOnSubmit) {
        goToNextStep();
        return;
      }
      const response = await handleDataSubmit(data, state.formData.id);
      setDocumentationData(response.documentation);
      goToNextStep();
    } catch (e) {
      Toaster.notify({
        kind: 'error',
        description: 'Um erro ocorreu ao salvar esta etapa. Por favor, tente novamente mais tarde.',
        origin: 'right-top',
        duration: 60000,
        button: 'Fechar',
        closeWhenClick: true,
      });
    }
  };

  return (
    <Flex direction="column" gap="$4">
      <FloraTypography as="h6" fontSize="exceptionsAuxiliar">
        Documentação
      </FloraTypography>
      <FloraTypography fontSize={'bodyLargeShort'}>
        A documentação obrigatória para o cadastro é solicitada pela Central de Inícios: documento oficial com foto (RG,
        Carteira de Habilitação ou Carteira de Trabalho na versão física). O processo inclui cobertura antifraude.
      </FloraTypography>
      <FormProvider {...methods}>
        <Box data-testid="documentation-form" as="form" onSubmit={methods.handleSubmit(onSubmitDocumentationFormData)}>
          <FormWrapper gap="$2" css={{ marginTop: '$4', marginBottom: 0 }}>
            <FormFieldset hasDivider={false}>
              <TogglableContent
                switchRegisterName="hasAdditionalDocumentation"
                title="Solicitar documentação adicional?"
                tooltipText="A documentação adicional é definida pela Central de Serviço."
                data-testid="toggle-additional-documentation"
              >
                <FormInput
                  registerName="documentationNote"
                  id="documentacao-necessaria"
                  labelText="Documentos:"
                  placeholder="Comprovante de residência"
                  data-testid="input-documentation-note"
                />
              </TogglableContent>
            </FormFieldset>
          </FormWrapper>
          <Flex css={{ margin: '$4 0' }} gap="$2" align="end">
            <ExclamationMarkTriangleOutlineIcon size={20} color="$10" />
            <FloraTypography fontSize="bodyLargeShort">
              A solicitação de documentação adicional é opcional.
            </FloraTypography>
          </Flex>
          <FormFooter />
        </Box>
      </FormProvider>
    </Flex>
  );
};
