import { DropdownItemsProps } from '@/components/shared';
import { PickUpStorePeriod } from '@/types';

export const checkboxPickUpStorePeriodValues: DropdownItemsProps[] = [
  {
    key: PickUpStorePeriod.DAYS,
    value: PickUpStorePeriod.DAYS,
    name: 'Dias',
  },
  {
    key: PickUpStorePeriod.WEEKS,
    value: PickUpStorePeriod.WEEKS,
    name: 'Semanas',
  },
  {
    key: PickUpStorePeriod.MONTHS,
    value: PickUpStorePeriod.MONTHS,
    name: 'Meses',
  },
];
