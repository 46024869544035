import { PaymentLocation, PaymentLocationInfo } from '@/types';
import * as yup from 'yup';

const MIN_CHECKBOX_ERROR_MESSAGE = 'Pelo menos 1 item deve ser selecionado.';

export const paymentLocationsFormDataSchema: yup.ObjectSchema<PaymentLocation> = yup.object({
  paymentLocations: yup.array().when('isEnabled', {
    is: true,
    then: schema =>
      schema
        .of(yup.mixed<PaymentLocationInfo>().oneOf(Object.values(PaymentLocationInfo)))
        .min(1, MIN_CHECKBOX_ERROR_MESSAGE),
    otherwise: schema => schema.optional().nullable(),
  }),
});
