import { FederativeUnit, StatesOfBrazil } from '@/types';

export const statesOfBrazilInfo = [
  {
    id: 1,
    federativeUnit: FederativeUnit.AC,
    name: StatesOfBrazil.ACRE,
  },
  {
    id: 2,
    federativeUnit: FederativeUnit.AL,
    name: StatesOfBrazil.ALAGOAS,
  },
  {
    id: 3,
    federativeUnit: FederativeUnit.AP,
    name: StatesOfBrazil.AMAPA,
  },
  {
    id: 4,
    federativeUnit: FederativeUnit.AM,
    name: StatesOfBrazil.AMAZONAS,
  },
  {
    id: 5,
    federativeUnit: FederativeUnit.BA,
    name: StatesOfBrazil.BAHIA,
  },
  {
    id: 6,
    federativeUnit: FederativeUnit.CE,
    name: StatesOfBrazil.CEARA,
  },
  {
    id: 7,
    federativeUnit: FederativeUnit.DF,
    name: StatesOfBrazil.DISTRITO_FEDERAL,
  },
  {
    id: 8,
    federativeUnit: FederativeUnit.ES,
    name: StatesOfBrazil.ESPIRITO_SANTO,
  },
  {
    id: 9,
    federativeUnit: FederativeUnit.GO,
    name: StatesOfBrazil.GOIAS,
  },
  {
    id: 10,
    federativeUnit: FederativeUnit.MA,
    name: StatesOfBrazil.MARANHAO,
  },
  {
    id: 11,
    federativeUnit: FederativeUnit.MT,
    name: StatesOfBrazil.MATO_GROSSO,
  },
  {
    id: 12,
    federativeUnit: FederativeUnit.MS,
    name: StatesOfBrazil.MATO_GROSSO_DO_SUL,
  },
  {
    id: 13,
    federativeUnit: FederativeUnit.MG,
    name: StatesOfBrazil.MINAS_GERAIS,
  },
  {
    id: 14,
    federativeUnit: FederativeUnit.PA,
    name: StatesOfBrazil.PARA,
  },
  {
    id: 15,
    federativeUnit: FederativeUnit.PB,
    name: StatesOfBrazil.PARAIBA,
  },
  {
    id: 16,
    federativeUnit: FederativeUnit.PR,
    name: StatesOfBrazil.PARANA,
  },
  {
    id: 17,
    federativeUnit: FederativeUnit.PE,
    name: StatesOfBrazil.PERNAMBUCO,
  },
  {
    id: 18,
    federativeUnit: FederativeUnit.PI,
    name: StatesOfBrazil.PIAUI,
  },
  {
    id: 19,
    federativeUnit: FederativeUnit.RJ,
    name: StatesOfBrazil.RIO_DE_JANEIRO,
  },
  {
    id: 20,
    federativeUnit: FederativeUnit.RN,
    name: StatesOfBrazil.RIO_GRANDE_DO_NORTE,
  },
  {
    id: 21,
    federativeUnit: FederativeUnit.RS,
    name: StatesOfBrazil.RIO_GRANDE_DO_SUL,
  },
  {
    id: 22,
    federativeUnit: FederativeUnit.RO,
    name: StatesOfBrazil.RONDONIA,
  },
  {
    id: 23,
    federativeUnit: FederativeUnit.RR,
    name: StatesOfBrazil.RORAIMA,
  },
  {
    id: 24,
    federativeUnit: FederativeUnit.SC,
    name: StatesOfBrazil.SANTA_CATARINA,
  },

  {
    id: 25,
    federativeUnit: FederativeUnit.SP,
    name: StatesOfBrazil.SAO_PAULO,
  },
  {
    id: 26,
    federativeUnit: FederativeUnit.SE,
    name: StatesOfBrazil.SERGIPE,
  },
  {
    id: 27,
    federativeUnit: FederativeUnit.TO,
    name: StatesOfBrazil.TOCANTINS,
  },
];
