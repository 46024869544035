import '@/utils/validators/yup';
import { BusinessHoursFormData, DayFormData, HourFormData } from '@/types';
import { compareAsc, isValid, parse } from 'date-fns';
import * as yup from 'yup';

const REQUIRED_ERROR_MESSAGE = 'Esse campo é obrigatório.';
const INVALID_TIME_ERROR_MESSAGE = 'Inserir um horário entre 00:00 e 23:59.';
const INVALID_TIME_RANGE_ERROR_MESSAGE = 'Insira hora final maior que a inicial.';

const parseTime = (timeStr: string) => parse(timeStr, 'HH:mm', new Date());

const validTimeTest: yup.TestConfig<string | undefined> = {
  name: 'valid-time-test',
  message: INVALID_TIME_ERROR_MESSAGE,
  test(value: string | undefined) {
    if (typeof value !== 'string') {
      return false;
    }

    return isValid(parseTime(value));
  },
};

const timeRangeTest: yup.TestConfig<string | undefined> = {
  name: 'time-range-test',
  message: INVALID_TIME_RANGE_ERROR_MESSAGE,
  test(value: string | undefined) {
    if (typeof value !== 'string') {
      return false;
    }

    const opensAtTime = parseTime(this.parent.opensAt);
    const closesAtTime = parseTime(this.parent.closesAt);

    if (isValid(opensAtTime) && isValid(closesAtTime)) {
      return compareAsc(closesAtTime, opensAtTime) === 1;
    }

    return true;
  },
};

const hourFormDataSchema: yup.ObjectSchema<HourFormData> = yup.object({
  closesAt: yup.string(),
  opensAt: yup.string(),
});

const hourFormDataRequiredSchema: yup.ObjectSchema<HourFormData> = yup.object({
  closesAt: yup.string().required(REQUIRED_ERROR_MESSAGE).test(validTimeTest).test(timeRangeTest),
  opensAt: yup.string().required(REQUIRED_ERROR_MESSAGE).test(validTimeTest).test(timeRangeTest),
});

const dayFormDataSchema: yup.ObjectSchema<DayFormData> = yup.object({
  closed: yup.boolean(),
  day: yup.array().of(yup.string().required()),
  hours: yup.array().of(yup.lazy(value => (value ? hourFormDataRequiredSchema : hourFormDataSchema))),
});
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const businessHoursFormDataSchema: yup.ObjectSchema<BusinessHoursFormData> = yup.object({
  days: yup.array().of(dayFormDataSchema).defined(),
  note: yup.string().optional(),
  holidays: yup.lazy(value =>
    yup.object({
      openOnHolidays: yup.boolean().required(REQUIRED_ERROR_MESSAGE),
      hours: yup.array().of(value.openOnHolidays ? hourFormDataRequiredSchema : hourFormDataSchema),
    }),
  ),
});
