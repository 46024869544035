import mitt from 'mitt';
import { DrawerInfoData, GENERIC_ERROR, MessageType } from '@/components';
import { SaveFormData } from '@/types';

type EventType = {
  NOTIFICATION: MessageType;
  OPEN_DRAWER_INFO: DrawerInfoData;
  CLOSE_DRAWER_INFO: void;
  UPDATE_CS_DATA: SaveFormData;
};

const mittImplementation = mitt<EventType>();

const onError = (options?: Partial<Omit<MessageType, 'kind'>>): void =>
  mittImplementation.emit('NOTIFICATION', {
    ...GENERIC_ERROR,
    kind: 'error',
    ...options,
  });

const events = {
  ...mittImplementation,
  error: onError,
};

export { events };
