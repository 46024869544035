import { OrderIncrementFormData } from '@/types';
import { currencyValueRangeTest, minCurrencyValueTest } from '@/utils';
import * as yup from 'yup';

const REQUIRED_ERROR_MESSAGE = 'Esse campo é obrigatório.';
const PRODUCTS_MIN_ERROR_MESSAGE = 'Insira pelo menos um produto.';
const MIN_QTY_ERROR_MESSAGE = 'Insira uma quantidade mínima maior que 0.';
const MIN_CHECKBOX_ERROR_MESSAGE = 'Pelo menos 1 item deve ser selecionado.';

export const orderIncrementFormDataSchema: yup.ObjectSchema<OrderIncrementFormData> = yup.object({
  allowIncrement: yup.boolean().required(REQUIRED_ERROR_MESSAGE),
  requiredQuantity: yup.string().when(['hasRequiredQuantity', 'allowIncrement'], {
    is: true,
    then: schema =>
      schema
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .integer()
        .integerMin(1, MIN_QTY_ERROR_MESSAGE)
        .required()
        .min(1, MIN_QTY_ERROR_MESSAGE),
    otherwise: schema => schema.optional(),
  }),
  hasRequiredQuantity: yup.boolean().required(),
  minimumValue: yup.string().when(['hasMinimumValue', 'allowIncrement'], {
    is: true,
    then: schema => schema.required(REQUIRED_ERROR_MESSAGE).test(minCurrencyValueTest).test(currencyValueRangeTest),
    otherwise: schema => schema.optional(),
  }),
  hasMinimumValue: yup.boolean().required(),
  maximumValue: yup.string().when(['hasMaximumValue', 'allowIncrement'], {
    is: true,
    then: schema => schema.required(REQUIRED_ERROR_MESSAGE).test(minCurrencyValueTest).test(currencyValueRangeTest),
    otherwise: schema => schema.optional(),
  }),
  hasMaximumValue: yup.boolean().required(),
  specificProducts: yup.array().when(['hasSpecificProducts', 'allowIncrement'], {
    is: true,
    then: schema =>
      schema.of(yup.string().required()).required(REQUIRED_ERROR_MESSAGE).min(1, PRODUCTS_MIN_ERROR_MESSAGE),
    otherwise: schema => schema.optional(),
  }),
  hasSpecificProducts: yup.boolean().required(),
  categories: yup
    .array()
    .min(1, MIN_CHECKBOX_ERROR_MESSAGE)
    .when('hasCategories', {
      is: false,
      then: schema => schema.min(0),
    }),
  hasCategories: yup.boolean().required(),
  note: yup.string(),
});
