import { Box, CSS } from '@grupoboticario/flora-react';
import { useTable } from '@/components/table/context';
import { TableHeaderCell, TableRow } from '@/components/table/components';

interface TableHeaderProps {
  css?: CSS;
}

const TableHeader = ({ css }: TableHeaderProps): JSX.Element => {
  const { columns } = useTable().tableState;

  return (
    <Box as="thead" css={css}>
      <TableRow>
        {columns.map(column => (
          <TableHeaderCell key={`table-header__${column.name}`} isSortable={column.isSortable} field={column.key}>
            {column.name}
          </TableHeaderCell>
        ))}
      </TableRow>
    </Box>
  );
};

export { TableHeader };
