import { FormFieldset, InputNote } from '@/components/';
import { Flex } from '@grupoboticario/flora-react';
import { PaymentHeader, PaymentLocations } from '@/components/payment-methods-form/';

const CashPayment = (): JSX.Element => (
  <Flex direction="column">
    <PaymentHeader />
    <PaymentLocations parentRegisterName="cashPayment" />
    <FormFieldset>
      <InputNote registerName="cashPayment.note" />
    </FormFieldset>
  </Flex>
);

export { CashPayment };
