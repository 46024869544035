import { ReactNode } from 'react';
import { Box, CSS, FloraTypography } from '@grupoboticario/flora-react';

interface TableCellProps {
  css?: CSS;
  children: ReactNode;
}

const TableCell = ({ css, children }: TableCellProps): JSX.Element => {
  const isTextOrNumber = ['string', 'number'].includes(typeof children);

  return (
    <Box
      as="td"
      css={{
        height: '$14',
        verticalAlign: 'middle',
        ...css,
      }}
    >
      {isTextOrNumber ? (
        <FloraTypography css={{ paddingLeft: '$4' }} fontSize="bodySmallShort">
          {children}
        </FloraTypography>
      ) : (
        children
      )}
    </Box>
  );
};

export { TableCell };
