import { LockAndLimit, LockAndLimitFormData } from '@/types';

export const normalizeLockAndLimitFormData = (
  lockAndLimit: LockAndLimit | undefined,
): LockAndLimitFormData | undefined => {
  if (!lockAndLimit) {
    return undefined;
  }

  return {
    ...lockAndLimit,
  };
};
