import { CSS, Flex, Spinner, FloraTypography } from '@grupoboticario/flora-react';

type LoadingSizeType = 'lg' | 'sm' | 'xl' | 'xs' | 'xxs' | 'md' | undefined;
interface LoadingProps {
  isLoading?: boolean;
  message?: string;
  subtitle?: string;
  size?: LoadingSizeType;
  thickness?: string;
  containerCss?: CSS;
  spinnerCss?: CSS;
}

const Loading = ({
  isLoading = true,
  message,
  subtitle,
  size = 'md',
  thickness,
  containerCss = {},
  spinnerCss = {},
}: LoadingProps): JSX.Element | null => {
  if (isLoading) {
    return (
      <Flex
        align="center"
        justify="center"
        direction="column"
        data-testid="loading"
        css={{
          height: '100%',
          color: '$linkDefault',
          ...containerCss,
        }}
      >
        <Spinner size={size} thickness={thickness} css={{ color: '$linkDefault', ...spinnerCss }} />
        {message && <FloraTypography css={{ marginTop: '$6' }}>{message}</FloraTypography>}
        {subtitle && (
          <FloraTypography fontSize="bodySmallStandard" css={{ marginTop: '$4' }}>
            {subtitle}
          </FloraTypography>
        )}
      </Flex>
    );
  }

  return null;
};

export { Loading };
