import { TableActionType } from '@/components/table/actions';
import { TableState } from '@/components/table/types';

const INITIAL_PAGE = 0;

export const getTableInitialState = (): TableState => ({
  columns: [],
  currentPage: INITIAL_PAGE,
  data: [],
  isInitialized: false,
  isLoading: false,
  sort: undefined,
  totalResults: undefined,
});

export type TableReducerType = (state: TableState, action: TableActionType) => TableState;

export const tableReducer: TableReducerType = (state, action) => {
  switch (action.type) {
    case 'SET_TABLE_COLUMNS':
      return {
        ...state,
        columns: action.payload,
      };

    case 'SET_TABLE_PAGE':
      return {
        ...state,
        currentPage: action.payload,
      };

    case 'SET_TABLE_SORT':
      return {
        ...state,
        sort: action.payload,
        currentPage: INITIAL_PAGE,
      };

    case 'SET_TABLE_CUSTOM_FILTER':
      return {
        ...state,
        customFilter: action.payload,
        currentPage: INITIAL_PAGE,
      };

    case 'RESET_STATE':
      return getTableInitialState();

    case 'LOAD_DATA_STARTED':
      return { ...state, isLoading: true };

    case 'LOAD_DATA_FAILED': {
      return { ...state, isLoading: false, data: [] };
    }

    case 'LOAD_DATA_COMPLETED':
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        totalResults: action.payload.totalResults,
      };

    case 'INIT_TABLE':
      return {
        ...state,
        isInitialized: true,
      };

    default:
      throw new Error(`Tipo de ação inválida`);
  }
};
