import React from 'react';
import { Box, CSS } from '@grupoboticario/flora-react';

interface TableRowProps {
  css?: CSS;
  children: React.ReactNode;
  isEvenRow?: boolean;
}

const TableRow = ({ css, children, isEvenRow }: TableRowProps): JSX.Element => (
  <Box
    as="tr"
    data-testid="table-row"
    css={{
      '> *': {
        backgroundColor: isEvenRow ? '$backgroundPrimary' : undefined,
      },
      '> *:first-child': {
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px',
      },
      '> *:last-child': {
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px',
      },
      ...css,
    }}
  >
    {children}
  </Box>
);

export { TableRow };
