import { Loading } from '@/components';
import { Flex, FloraTypography } from '@grupoboticario/flora-react';

const LoadingTableData = (): JSX.Element => (
  <Flex data-testid="table-loading" direction="column" align="center" justify="center" css={{ marginTop: '$20' }}>
    <Loading size="lg" thickness="4px" />

    <FloraTypography as="h6" fontSize="exceptionsAuxiliar" css={{ marginTop: '$5', color: '$dark-light' }}>
      Carregando as informações
    </FloraTypography>

    <FloraTypography fontSize="bodyLargeShort" css={{ marginTop: '$4', color: '$backgroundAltSecondary' }}>
      Por favor, aguarde um instante.
    </FloraTypography>
  </Flex>
);

export { LoadingTableData };
