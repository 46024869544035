import { OrderIncrement, OrderIncrementFormData } from '@/types';
import { formatCurrency } from '@/utils';

export const normalizeOrderIncrementFormData = (
  orderIncrement: OrderIncrement | undefined,
): OrderIncrementFormData | undefined => {
  if (!orderIncrement) {
    return undefined;
  }

  return {
    ...orderIncrement,
    minimumValue: formatCurrency(Number(orderIncrement.minimumValue)),
    maximumValue: formatCurrency(Number(orderIncrement.maximumValue)),
  };
};
