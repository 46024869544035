import { BusinessHoursFormData, BusinessHoursTimeRange, DayOfTheWeek, SaveBusinessHoursRequestType } from '@/types';

export const formatBusinessHoursFormData = (
  formData: BusinessHoursFormData,
  id: string,
): SaveBusinessHoursRequestType => {
  const days = formData.days.map(day => ({
    closed: day.closed,
    hours: day.closed ? [] : ((day.hours as BusinessHoursTimeRange[]) ?? []),
    day: (day.day ?? []) as DayOfTheWeek[],
  }));

  const hours = formData.holidays.hours.map(hour =>
    hour
      ? {
          opensAt: hour.opensAt,
          closesAt: hour.closesAt,
        }
      : [],
  ) as BusinessHoursTimeRange[];

  return {
    _id: id,
    businessHours: {
      days,
      holidays: {
        openOnHolidays: formData.holidays.openOnHolidays,
        hours: formData.holidays.openOnHolidays ? hours : [],
      },
      note: formData.note,
    },
    step: '2',
  };
};
