import { DeliveryCondition, DeliveryFormData } from '@/types';
import { minCurrencyValueTest } from '@/utils';
import * as yup from 'yup';

const REQUIRED_ERROR_MESSAGE = 'Esse campo é obrigatório.';
const NOT_ALLOWED_REGIONS_MIN_ERROR_MESSAGE = 'Insira pelo menos uma região.';

const deliveryConditionSchema: yup.ObjectSchema<DeliveryCondition> = yup.object({
  deliveryCost: yup.string().when('hasFreeDelivery', {
    is: false,
    then: schema => schema.required(REQUIRED_ERROR_MESSAGE).test(minCurrencyValueTest),
    otherwise: schema => schema.optional(),
  }),
  hasFreeDelivery: yup.boolean().required(),
  minimumPurchaseValue: yup.string().test(minCurrencyValueTest).required(),
});

export const deliveryFormDataSchema: yup.ObjectSchema<DeliveryFormData> = yup.object({
  hasDelivery: yup.boolean().required(),
  deliveryCost: yup.string().when(['hasDelivery', 'hasFreeDelivery'], {
    is: (hasDelivery: boolean, hasFreeDelivery: boolean) => hasDelivery && !hasFreeDelivery,
    then: schema => schema.required(REQUIRED_ERROR_MESSAGE).test(minCurrencyValueTest),
    otherwise: schema => schema.optional(),
  }),
  hasFreeDelivery: yup.boolean().optional(),
  hasFreeDeliveryOnFirstOrder: yup.boolean().optional(),
  hasSpecialConditions: yup.boolean().required(),
  specialConditions: yup.array().when(['hasSpecialConditions', 'hasDelivery'], {
    is: true,
    then: schema => schema.of(deliveryConditionSchema).required().min(1),
    otherwise: schema => schema.optional(),
  }),
  hasFreeDeliveryDuringPandemic: yup.boolean().required(),
  hasNotAllowedDeliveryRegions: yup.boolean().required(),
  notAllowedDeliveryRegions: yup.array().when(['hasDelivery', 'hasNotAllowedDeliveryRegions'], {
    is: true,
    then: schema =>
      schema
        .of(yup.string().required())
        .required(NOT_ALLOWED_REGIONS_MIN_ERROR_MESSAGE)
        .min(1, NOT_ALLOWED_REGIONS_MIN_ERROR_MESSAGE),
    otherwise: schema => schema.optional(),
  }),
  allowDeliveryToThirdParties: yup.boolean().required(),
  deliveryToThirdPartiesRules: yup.array().optional(),
  note: yup.string(),
});
