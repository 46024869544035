import React from 'react';
import { Box, Input, InputProps } from '@grupoboticario/flora-react';
import { Controller, useFormContext } from 'react-hook-form';

interface FormInputCurrencyProps extends InputProps {
  registerName: string;
  onBlur?: (event: unknown) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormInputCurrency = ({ registerName, onBlur, onChange, disabled, ...rest }: FormInputCurrencyProps) => {
  const {
    formState: { errors, dirtyFields },
    control,
  } = useFormContext();

  const handlerOnblur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onBlur) {
      return onBlur(e);
    }
    return null;
  };

  const handlerOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      return onChange(e);
    }
    return null;
  };

  return (
    <Box
      css={{
        '& > div': {
          height: 'auto',
        },
      }}
    >
      <Controller
        name={registerName}
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            key={registerName}
            id={registerName}
            invalidText={disabled ? undefined : (errors[registerName]?.message as string)}
            invalid={disabled ? undefined : !!errors[registerName]}
            data-valid={disabled ? undefined : dirtyFields[registerName] && !errors[registerName]}
            onBlur={e => {
              field.onBlur();
              handlerOnblur(e);
            }}
            onChange={e => {
              field.onChange(e);
              handlerOnchange(e);
            }}
            disabled={disabled}
            mask={{
              style: 'currency',
            }}
            {...rest}
          />
        )}
      />
    </Box>
  );
};

export { FormInputCurrency };
